import { Component, OnInit } from '@angular/core';
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { RedirectService } from '../../shared/services/redirect.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest } from 'rxjs';
import { JwtPayload } from 'atfcore-commonclasses';
import { onceIfNotEmpty } from 'projects/gi/src/app/ngrx/util';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { authControl } from 'projects/gi/src/app/shared/models/global-application-data.model';

@Component({
  selector: 'app-home-admin-hrbp',
  templateUrl: './home-admin-hrbp.component.html',
  styleUrls: ['./home-admin-hrbp.component.scss']
})
export class HomeAdminHrbpComponent extends BaseSubscriberComponent implements OnInit {

  menuButtons: menuButton[];
  authObject;
  canImpersonificate: boolean;
  isImpersonification: boolean;

  constructor(
    public redirectService: RedirectService,
    public translate: TranslateService,
    private store: Store<fromApp.AppState>) {
    super();
  }

  ngOnInit() {

    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang).pipe(onceIfNotEmpty());
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser).pipe(onceIfNotEmpty());
    const isImpersonification$: Observable<boolean> = this.store.select(fromApp.getIsImpersonification);
    combineLatest([applicationLang$, loggedUser$, isImpersonification$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([applicationLang, loggedUser, isImpersonification]) => {
          if (applicationLang && loggedUser) {
            this.authObject = authControl(loggedUser && loggedUser.auths);
            this.canImpersonificate = loggedUser.auths.includes('PERFORMANCEMANAGEMENT_IMPERSONATE_USER');
            this.isImpersonification = isImpersonification;
          }
        })

    this.translate.get(
      [
        'admin.buttons.FIRST_TITLE_BUTTON',
        'admin.buttons.FIRST_SUBTITLE_BUTTON',
        'admin.buttons.SECOND_TITLE_BUTTON',
        'admin.buttons.SECOND_SUBTITLE_BUTTON',
        'admin.buttons.THIRD_TITLE_BUTTON',
        'admin.buttons.THIRD_SUBTITLE_BUTTON',
        'admin.buttons.FOURTH_TITLE_BUTTON',
        'admin.buttons.FOURTH_SUBTITLE_BUTTON',
        'admin.buttons.FIFTH_TITLE_BUTTON',
        'admin.buttons.FIFTH_SUBTITLE_BUTTON',
        'admin.buttons.SIXTH_TITLE_BUTTON',
        'admin.buttons.SIXTH_SUBTITLE_BUTTON',
        'admin.buttons.SEVENTH_TITLE_BUTTON',
        'admin.buttons.SEVENTH_SUBTITLE_BUTTON'
      ]).subscribe(translations => {
        if (this.authObject && this.authObject.isAdmin) {

          this.menuButtons = [
            {
              id: "admin-console",
              title: translations["admin.buttons.FIRST_TITLE_BUTTON"],
              subtitle: translations["admin.buttons.FIRST_SUBTITLE_BUTTON"],
              icon: "../../../assets/img/accordion-img/last-control-ninebox.svg",
            },
            {
              id: "process-setup",
              title: translations["admin.buttons.SECOND_TITLE_BUTTON"],
              subtitle: translations["admin.buttons.SECOND_SUBTITLE_BUTTON"],
              icon: "../../../assets/img/accordion-img/my-team.svg",
            },
            {
              id: "edit-data",
              title: translations["admin.buttons.THIRD_TITLE_BUTTON"],
              subtitle: translations["admin.buttons.THIRD_SUBTITLE_BUTTON"],
              icon: "../../../assets/img/accordion-img/not-saved-modifies.svg"
            }]
          if (!this.isImpersonification && this.canImpersonificate) {
            this.menuButtons.push({
              id: "impersonification",
              title: translations["admin.buttons.FOURTH_TITLE_BUTTON"],
              subtitle: translations["admin.buttons.FOURTH_SUBTITLE_BUTTON"],
              icon: "../../../assets/img/accordion-img/my-experience.svg"
            })
          }
          this.menuButtons.push({
            id: "charge-objectives",
            title: translations["admin.buttons.FIFTH_TITLE_BUTTON"],
            subtitle: translations["admin.buttons.FIFTH_SUBTITLE_BUTTON"],
            icon: "../../../assets/img/accordion-img/goal-setting.svg"
          });
          this.menuButtons.push({
            id: "excel-report",
            title: translations["admin.buttons.SIXTH_TITLE_BUTTON"],
            subtitle: translations["admin.buttons.SIXTH_SUBTITLE_BUTTON"],
            icon: "../../../assets/img/accordion-img/idp.svg"
          });
          this.menuButtons.push({
            id: "excel-report-my-experience",
            title: translations["admin.buttons.SEVENTH_TITLE_BUTTON"],
            subtitle: translations["admin.buttons.SEVENTH_SUBTITLE_BUTTON"],
            icon: "../../../assets/img/application-pics/my-experience-inside.svg"
          });
        } else if (this.authObject && this.authObject.isHRBP) {
          this.menuButtons = [
            {
              id: "hrbp-console",
              title: translations["admin.buttons.FIRST_TITLE_BUTTON"],
              subtitle: translations["admin.buttons.FIRST_SUBTITLE_BUTTON"],
              icon: "../../../assets/img/accordion-img/last-control-ninebox.svg"
            }
          ]
        }
      });
  }

  goToPage(id: string) {
    switch (id) {
      case 'admin-console':
        this.redirectService.goToAdminOrganics();
        break;

      case 'hrbp-console':
        this.redirectService.goToHrbpOpenedLists();
        break;

      case 'process-setup':
        this.redirectService.goToSetupProcessesHome();
        break;

      case 'edit-data':
        this.redirectService.goToEditDataAdmin();
        break;

      case 'charge-objectives':
        this.redirectService.goToChargeObjectivesAdmin();
        break;

      case 'impersonification':
        this.redirectService.goToImpersonificationAdmin();
        break;

      case 'excel-report':
        this.redirectService.goToExcelReportsAdmin();
        break;

      case 'excel-report-my-experience':
        this.redirectService.goToExcelReportsMyExperienceAdmin()
        break;
    }
  }

  ngOnDestroy() {

  }

}

export interface menuButton {
  id: string,
  title: string,
  subtitle: string,
  icon: string,
  isDisabled?: boolean
}