import { Router } from "@angular/router";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable, Subscription, combineLatest } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from '../../core/ngrx/core.actions';
import { AuthService } from 'src/app/auth/services/auth.service';
import { JwtPayload } from 'src/cm2-commonclasses';
import { onceIfNotEmpty } from 'projects/gi/src/app/ngrx/util';
import { authControl } from '../models/global-application-data.model';

/*
*    Servizio che gestisce i vari redirect
*/
@Injectable()
export class RedirectService implements OnDestroy {
  isMainMenuSidebarOpened: boolean;
  isProfileMenuSidebarOpened: boolean;
  sidenavObservables$: Subscription;
  loggedUser: JwtPayload;
  authObject;
  constructor(private store: Store<fromApp.AppState>,
    private authService: AuthService,
    private router: Router) {
    // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
    let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
    // Recupero dallo Store applicativo le variabili che mi indicano se le sidenav sono aperte
    let isProfileMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isProfileMenuSidebarOpened);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser).pipe(onceIfNotEmpty());
    const combinedSelectes$ = combineLatest(isMainMenuSidebarOpened$, isProfileMenuSidebarOpened$, loggedUser$);

    this.sidenavObservables$ = combinedSelectes$.subscribe(
      ([isMainMenuSidebarOpened, isProfileMenuSidebarOpened, loggedUser]) => {
        this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
        this.isProfileMenuSidebarOpened = isProfileMenuSidebarOpened;
        this.loggedUser = loggedUser;
        if (this.loggedUser && this.loggedUser.auths) {
          this.authObject = authControl(this.loggedUser && this.loggedUser.auths);
        }
      });
  }

  // Quando il componente è distrutto, eseguo anche l'unsubscribe
  ngOnDestroy() {
    if (this.sidenavObservables$) {
      this.sidenavObservables$.unsubscribe();
    }
  }

  goBack(route: any, secondPage?: boolean, isTouchpoint?: boolean) {
    if (isTouchpoint) {
      if (secondPage) {
        this.router.navigate(['../'], { relativeTo: route });
      } else {
        this.router.navigate(['../../home'], { relativeTo: route });
      }
    } else {
      this.router.navigate(['../home'], { relativeTo: route });
    }
  }

  // Chiude la sidebar di sinistra contente il menu principale
  closeSidenavMainMenu() {
    this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
  }

  // Chiude la sidebar di destra con il profilo utente
  closeSidenavProfileMenu() {
    this.store.dispatch(new CoreActions.CloseProfileMenuSidebar());
  }

  // Porta all'home page
  goToHome() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    // if (this.authObject.isAdmin) {
    //     this.router.navigate(['homeManage']);
    // } else if(this.authObject.isHRBP) {
    //     this.router.navigate(['homeManage']);
    // } else 
    if (this.authObject.isManager) {
      this.router.navigate(['users/home']);
    } else {
      this.router.navigate(['contributor/home']);
    }
  }

  // Vai al portale admin/hrbp
  goToHomeManage() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['homeManage']);
  }

  // Vai al setup processi
  goToSetupProcesses() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (this.authService.isAdminMode()) {
      this.router.navigate(['admin/setupProcessesHome']);
    }
  }

  // Porta alle notifiche
  goToNotifications() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (this.authService.isAdminMode()) {
      this.router.navigate(['admin/notifications']);
    }
  }

  // Vai alla pianificazione organici Admin
  goToAdminOrganics() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['admin/home']);
  }

  // Vai ai report excel Admin
  goToExcelReportsAdmin() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['admin/excelReports']);
  }

  // Vai ai report excel Admin MyExperience
  goToExcelReportsMyExperienceAdmin() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['admin/excelReportsMyExperience']);
  }

  // Vai a setup dei processi Admin
  goToSetupProcessesHome() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['admin/setupProcessesHome']);
  }

  // Vai alla Home Utente
  goToUsersHome() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['users/home']);
  }

  // Vai alla Home HRBP liste aperte da confermare
  goToHrbpOpenedLists() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['hrbp/organicsList']);
  }

  // Porta alla pagina del profilo
  goToProfile(year: any) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['users/profile/' + year]);
  }

  goToMyTeam() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['users/myTeam']);
  }

  goToDashboard(isAdmin?) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (isAdmin) {
      this.router.navigate(['../admin/dashboard']);
    } else {
      this.router.navigate(['../hrbp/dashboard']);
    }
  }

  goToSettings() {
    // this.router.navigate(['users/myTeam']);
  }

  goToFAQ() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['users/faq']);
  }

  goToTutorial() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['users/tutorials']);
  }

  goToPerformance(phaseId, year, userId?: string, isHistory?: boolean) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }

    if (!isHistory) {
      if (userId) {
        this.router.navigate(['users/performance/', phaseId, year, userId]);
      } else {
        this.router.navigate(['users/performance/', phaseId, year]);
      }
    } else {
      this.router.navigate(['users/performance/history/', phaseId, year, userId]);
    }
  }

  goToSkills(phaseId, year, userId?: string, isHistory?: boolean) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }

    if (!isHistory) {
      if (userId) {
        this.router.navigate(['users/competence/', phaseId, year, userId]);
      } else {
        this.router.navigate(['users/competence/' + phaseId, year]);
      }
    } else {
      this.router.navigate(['users/competence/history/', phaseId, year, userId]);
    }
  }

  goToPotential(phaseId, year, userId?: string, isHistory?: boolean) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (!isHistory) {
      if (userId) {
        this.router.navigate(['users/potential/', phaseId, year, userId]);
      } else {
        this.router.navigate(['users/potential/' + phaseId, year]);
      }
    } else {
      this.router.navigate(['users/potential/history/', phaseId, year, userId]);
    }
  }
  goToIDP(phaseId, year, userId?: string, isHistory?: boolean) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (!isHistory) {
      if (userId) {
        this.router.navigate(['users/individualPlan/', phaseId, year, userId]);
      } else {
        this.router.navigate(['users/individualPlan/' + phaseId, year]);
      }
    } else {
      this.router.navigate(['users/individualPlan/history/', phaseId, year, userId]);
    }
  }

  goToFeedback(phaseId, year, userId?: string, isHistory?: boolean) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (!isHistory) {
      if (userId) {
        this.router.navigate(['users/feedbackDialogue/', phaseId, year, userId]);
      } else {
        this.router.navigate(['users/feedbackDialogue/' + phaseId, year]);
      }
    } else {
      this.router.navigate(['users/feedbackDialogue/history/', phaseId, year, userId]);
    }
  }

  goToGoal(phaseId, year, userId?: string, isHistory?: boolean) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (!isHistory) {
      if (userId) {
        this.router.navigate(['users/goalSetting/', phaseId, year, userId]);
      } else {
        this.router.navigate(['users/goalSetting/' + phaseId, year]);
      }
    } else {
      this.router.navigate(['users/goalSetting/history/', phaseId, year, userId]);
    }
  }

  goToComparison(year?: any, userId?: string) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['users/comparison', year, userId]);
  }

  goToComparisonPotential(year?: any, userId?: string) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['users/comparison-potential', year, userId]);
  }

  goToTutorialList() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['users/tutorials']);
  }

  goToNineBoxMatrix(year) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['users/nineboxmatrix/' + year]);
  }

  goToSurveyVideo(videoId, fromTutorialPage?: boolean) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (fromTutorialPage) {
      this.router.navigate(['users/tutorials/videoTutorial', videoId]);
    } else {
      this.router.navigate(['users/videoTutorial', videoId]);
    }
  }

  goToBadges(userId?) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (userId) {
      this.router.navigate(['users/badgesList'], userId);
    } else {
      this.router.navigate(['users/badgesList']);
    }
  }

  goToContributorBadges() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }

    this.router.navigate(['contributor/badgesList']);

  }

  goToUserEvaluationCard(year) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['contributor/evaluation/', year]);

  }

  goTo9Box(year?: string, userId?: string) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (this.authService.isAdminMode()) {
      if (userId) {
        this.router.navigate(['admin/nineboxmatrix', year, userId]);
      } else {
        this.router.navigate(['admin/nineboxmatrix', year]);
      }
    } else if (this.authService.isHrbpMode()) {
      if (userId) {
        this.router.navigate(['hrbp/nineboxmatrix', year, userId]);
      } else {
        this.router.navigate(['hrbp/nineboxmatrix', year]);
      }
    } else if (this.authService.isManagerMode()) {
      if (userId) {
        this.router.navigate(['users/nineboxmatrix', year, userId]);
      } else {
        this.router.navigate(['users/nineboxmatrix', year]);
      }
    }
  }

  goToContributor() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['contributor/home']);
  }

  goToStoricEvaluation(userId, year?) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (year) {
      this.router.navigate(['users/storicEvaluation', userId, year]);
    } else {
      this.router.navigate(['users/storicEvaluation', userId]);
    }
  }

  // Vai al modifica dati lato admin
  goToEditDataAdmin() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['admin/editDataAdmin']);
  }

  // Vai al caricamento obiettivi lato admin
  goToChargeObjectivesAdmin() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['admin/chargeObjectivesAdmin']);
  }

  // Vai all'impersonifica lato admin
  goToImpersonificationAdmin() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['admin/impersonificateAdmin']);
  }

  goToAdmin() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['homeManage']);
  }

  goToUser() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/users/home']);
  }

  // Vai alla home del myExperience
  goToMyExperience() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/myExperience/myExperienceHome']);
  }

  // Vai alle esperienze del myExperience
  goToMyExperienceExperiences(customUserId?: string) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }

    if (customUserId) {
      this.router.navigate(['/myExperience/experiences/' + customUserId]);
    } else {
      this.router.navigate(['/myExperience/experiences']);
    }
  }

  // Vai alle competenze del myExperience
  goToMyExperienceCompetences(customUserId?: string) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }

    if (customUserId) {
      this.router.navigate(['/myExperience/competences/' + customUserId]);
    } else {
      this.router.navigate(['/myExperience/competences']);
    }
  }

  // Vai alle personas del myExperience
  goToMyExperiencePersonas(customUserId?: string) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (customUserId) {
      this.router.navigate(['/myExperience/personas/' + customUserId]);
    } else {
      this.router.navigate(['/myExperience/personas']);
    }
  }

  // Vai al team experience
  goToMyTeamExperience() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/myExperience/teamExperience']);
  }

  // Vai alla console experience
  goToExperienceConsole() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/myExperience/experienceConsole']);
  }

  // Vai al questionario personas
  goToPersonasSurvey() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/myExperience/personasSurvey']);
  }

  // Vai a creare una nuova esperienza
  goToCreateNewExperience(userId?: string) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (userId) {
      this.router.navigate(['/myExperience/newEditExperienceWithUserId/' + userId]);
    } else {
      this.router.navigate(['/myExperience/newEditExperience']);
    }
  }

  // Vai a modificare un'esperienza
  goToEditExperience(experienceId: string, customUserId?: string) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }

    if (customUserId) {
      this.router.navigate(['/myExperience/newEditExperience/' + experienceId, customUserId]);
    } else {
      this.router.navigate(['/myExperience/newEditExperience/' + experienceId]);
    }
  }

  // Vai a vedere dettagli di un esperienza
  goToExperienceDetail(experienceId, customUserId?: string, isHrbp?: boolean) {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    if (customUserId) {
      if (isHrbp) {
        this.router.navigate(['/myExperience/experienceDetailHrbp/' + experienceId, customUserId]);
      } else {
        this.router.navigate(['/myExperience/experienceDetail/' + experienceId, customUserId]);
      }
    } else {
      this.router.navigate(['/myExperience/experienceDetail/' + experienceId]);
    }
  }

  // Vai a vedere lista profili personas
  goToPersonasProfileList() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.router.navigate(['/myExperience/personasProfileList']);
  }
}
