import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MagicModalService } from './magic-modal.service';


@Component({
  selector: 'magic-modal',
  templateUrl: './magic-modal.component.html',
  styleUrls: ['./magic-modal.component.scss']
})
export class MagicModalComponent implements OnInit {
  @Input() id: string;
  @Input() isFilterComparisonModal: boolean;
  @Input() modalType: string; // 'profile-marks',
  @Input() inputClass: string; // full-size, extended
  @Input() showBackButton = true;
  @Input() closeWithoutButton;
  @Input() imageSrc: string;
  @Input() title: string;
  @Input() description: string;
  @Input() actionLabel: string;
  @Input() actionDisabled: boolean = false;
  @Input() secondaryActionLabel: string;
  @Input() secondaryActionDisabled: boolean = false;
  @Input() textActionLabel: string;
  @Input() secondLayer: boolean = false;
  @Input() closeWithButtonClose: boolean;
  @Input() isAlleanza: boolean;
  /** Se true, usa ng-content per mostrare una descrizione */
  @Input() useNgContent: boolean;
  @Input() showSecondBackButton: boolean;
  @Input() fromCascadingWhatModal: boolean;
  
  @Output() onCloseWithoutButtonFunction = new EventEmitter<any>();
  @Output() onCloseWitCloseFunction = new EventEmitter<any>();
  @Output() onBackButtonFunction = new EventEmitter<any>();
  @Output() onActionClicked = new EventEmitter<any>();
  @Output() onSecondaryActionClicked = new EventEmitter<any>();

  @Output() onTextActionClicked = new EventEmitter<any>();
  private element: any;
  @ViewChild('modalEl') modalEl;
  focusedElBeforeOpen: any;

  constructor(
    private modalService: MagicModalService,
    private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const modal = this;
    // Devo assicurarmi di avere l'id della modale
    if (!this.id) {
      return;
    }

    // Affinché venga resa visibile ovunque, la appendo appena dopo la chiusura del </body>
    document.body.appendChild(this.element);

    // Chiudo la modale al click sullo sfondo
    this.element.addEventListener('click', (e: any) => {
      if (e.target.className == 'modal') {
        if (this.closeWithoutButton) {
          this.onCloseWithoutButtonFunction.emit()
          modal.close();
        }
      }
    });

    // Chiudo la modale al click sul close
    this.element.addEventListener('click', (e: any) => {
      if (e.target && (e.target.id == 'close-icon-modal' || e.target.id == 'icon-close-svg' || e.target.id == 'icon-close-path')) {
        if (this.closeWithButtonClose) {
          this.onCloseWitCloseFunction.emit();
        }
      }
    });

    // Aggiungo la modale al servizio affinché venga resa accessibile dai vari componenti
    this.modalService.add(this);
  }

  // Apre la modale
  open(): void {
    this.focusedElBeforeOpen = document.activeElement;
    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
    setTimeout(() => {
      this.modalEl.nativeElement.focus();
    }, 500)
  }

  // Chiude la modale
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
    if (this.focusedElBeforeOpen) {
      this.focusedElBeforeOpen.focus();
    }
  }

  emitOnActionClicked(data: any) {
    this.onActionClicked.emit(data);
  }

  emitOnSecondaryActionClicked(data: any) {
    this.onSecondaryActionClicked.emit(data);
  }

  emitOnTextActionClicked() {
    this.onTextActionClicked.emit();
  }

  // Quando la direttiva è distrutta, tolgo la modale dal servizio
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    if (typeof this.element.remove === 'function') {
      this.element.remove();
    } else {
      this.element.parentNode.removeChild(this.element);
    }
  }
}
