import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { map, switchMap, withLatestFrom, takeWhile, mergeMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromCore from './core.reducers';
import * as fromApp from '../../ngrx/app.reducers';
import { UrlService } from '../../shared/services/url.service';
import { AppUrl } from '../../shared/models/url.model';
import * as CoreActions from './core.actions';
import * as ProfileActions from '../../users/profile/ngrx/profile.actions';
import { LangsService } from '../services/langs.service';
import { ToastrService } from 'ngx-toastr';
import { Lang, SenecaResponse } from "../../../cm2-commonclasses";
import { TranslateService } from '@ngx-translate/core';
import { timer, from, Observable } from 'rxjs';
import * as AuthActions from '../../auth/ngrx/auth.actions';
import { AuthService } from '../../auth/services/auth.service';
import { GlobalApplicationData } from 'projects/gi/src/app/shared/models/global-application-data.model';

@Injectable()
export class CoreEffects {
  constructor(private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private langsService: LangsService,
    private toastr: ToastrService,
    private urlService: UrlService,
    private translate: TranslateService,
    private store: Store<fromCore.CoreState>) {
  }

  // Url dell'applicazione
  applicationUrl: AppUrl;
  // Di default, inserisco la lingua del browser dell'utente, recupera grazie ad una funzione 
  defaultLang: string = this.langsService.getBrowserLang();
  // Verifica se l'utente è autenticato
  isAuthenticated: boolean;
  // Tiny token
  tinyToken: string;
  // Token intero
  tokenObj;
  authObject: any;
  forceRefreshUser?: boolean;
  redirectUrl?: string;

  @Effect()
  startRenewTokenPolling$ = this.actions$
    .pipe(
      ofType(CoreActions.START_RENEW_TOKEN_POLLING)
      , map((action: CoreActions.StartRenewTokenPolling) => {
        this.redirectUrl = action && action.payload && action.payload.redirectUrl || '';
        this.forceRefreshUser = action && action.payload && action.payload.forceRefreshUser || false;
        return action.payload;
      })
      , switchMap(
        () => timer(0, 3000000)
          .pipe(
            withLatestFrom(this.store.select(fromApp.isAuthenticated))
            , map(([action, isAuthenticated]) => {
              this.isAuthenticated = isAuthenticated;
            })
            , takeWhile(() => this.isAuthenticated),
            switchMap(() => {
              let sessionStorageToken: string = sessionStorage.getItem('token');
              if (sessionStorageToken) {
                sessionStorage.removeItem('token');
                return from(this.authService.renewToken(sessionStorageToken, this.forceRefreshUser));
              } else {
                throw (new Error('TOKEN_NOT_FOUND'));
              }
            })
            , switchMap(
              (tinyTokenObj: SenecaResponse<string>) => {
                if (tinyTokenObj.error) {
                  throw (new Error(tinyTokenObj.error));
                } else {
                  this.tinyToken = tinyTokenObj.response;
                  return from(this.authService.getJWTToken(tinyTokenObj.response));
                }
              })
            , map((tokenObj: SenecaResponse<string>) => {
              if (tokenObj.error) {
                throw (new Error(tokenObj.error));
              } else {
                this.tokenObj = tokenObj.response;
                return this.store.dispatch(new AuthActions.SetToken(this.tinyToken));
              }
            })
            , map(() => {
              return this.store.dispatch(new ProfileActions.DecodeToken(this.tokenObj));
            })
            , withLatestFrom(this.store.select(fromApp.getLoggedUser))
            , switchMap(([action, loggedUser]) => {
              if (this.redirectUrl) {
                this.router.navigate([this.redirectUrl]);
              } else {
                // Se non ce l'ho nemmeno nel session storage, allora lo setto per evitare il redirect automatico in home page
                let sessionStorageRedirectUrl: string = sessionStorage.getItem('redirectUrl');
                if (!sessionStorageRedirectUrl) {
                  let url = this.router.url;
                  if (url) {
                    sessionStorage.setItem('redirectUrl', url);
                  }
                }
              }

              let langToUse = this.langsService.getUserLang(loggedUser.user);
              this.langsService.useLanguage(langToUse);
              return [{
                type: CoreActions.SET_APPLICATION_LANG,
                payload: langToUse
              },
              {
                type: AuthActions.RETRIEVE_USER_ACKNOWLEDGES
              },
              {
                type: CoreActions.START_COUNT_ASKED_COMPETENCES
              },
              {
                type: CoreActions.GET_CURRENT_YEAR
              }
              ];
            })
          )
      )
      , catchError((err, caught) => {
        this.translate.setDefaultLang(this.defaultLang);
        if (err && err.message) {
          if (err.message == "OLD_TOKEN_NOT_FOUND") {
            this.toastr.error("Sessione scaduta");
          } else {
            this.toastr.error(this.translate.instant('errors.' + err.message));
          }
        }
        return caught;
      })
    )

  @Effect()
  coreActions$ = this.actions$
    .pipe(
      ofType(CoreActions.GET_AVAILABLE_LANGS)
      , withLatestFrom(this.store.select(fromApp.getAvailableLangs))
      , switchMap(([action, storeLangs]) => {
        if (storeLangs && storeLangs.length) {
          this.store.dispatch(new CoreActions.GetAvailableLangsFinished());
        } else {
          return this.langsService.getAvailableLangs();
        }
      })
      , map(
        (senecaResponse: SenecaResponse<Lang[]>) => {
          if (senecaResponse.response) {
            for (let i = 0, langsLength = senecaResponse.response.length; i < langsLength; i++) {
              if (senecaResponse.response[i] && senecaResponse.response[i].mandatory && senecaResponse.response[i].langCode) {
                this.defaultLang = senecaResponse.response[i].langCode.substring(0, 2);
                break;
              }
            }

            return this.store.dispatch(new ProfileActions.SaveAvailableLangs(senecaResponse.response));
          }
        }
      )
      , withLatestFrom(this.store.select(fromApp.getGlobalApplicationData))
      , switchMap(([action, savedGlobalApplicationData]) => {
        this.translate.setDefaultLang(this.defaultLang);
        return this.translate.use(this.defaultLang).pipe(
          map(() => savedGlobalApplicationData)
        );
      }),
      switchMap((savedGlobalApplicationData) => {
        if (!savedGlobalApplicationData) {
          this.applicationUrl = this.urlService.getApplicationUrl();

          let newGlobalApplicationData = new GlobalApplicationData(
            this.applicationUrl.baseUrl,
            '../index.html',
            '../isMaintenance.xml',
            'eTicketing-user/?#/app/eTicketUserApp/eTicketing',
            null,
            false,
            false,
            [],
            [],
            false,
            null,
            null
          );

          return [{
            type: CoreActions.SET_CORE_APPLICATION_DATA,
            payload: newGlobalApplicationData
          }, {
            type: CoreActions.GET_AVAILABLE_LANGS_FINISHED
          }, {
            type: CoreActions.SET_DEFAULT_LANG,
            payload: this.defaultLang
          }
          ]
        } else {
          return [{
            type: CoreActions.GET_AVAILABLE_LANGS_FINISHED
          }];
        }
      })
      , catchError((err, caught) => {
        this.translate.setDefaultLang(this.defaultLang);
        if (err && err.message) {
          this.toastr.error(this.translate.instant('errors.' + err.message));
        }
        this.store.dispatch(new CoreActions.GetAvailableLangsFinished());
        return caught;
      })
    )
}