<div class="page-container" fxLayout="column" fxLayoutAlign="start center" *ngIf="langs && langs.length">
    <div class="banner-header medium-size" fxLayout="row" fxLayoutAlign="start start">
      <!-- Mask content -->
      <div class="banner-header-content-mask">
        <!-- Titolo pagina -->
        <div class="banner-header-title"
          fxLayout="row" fxLayoutAlign="start center">
          <h2 tabindex="0" translate="generic.PLATFORM_CHOOSE"></h2>
        </div>
      </div>
    </div>
  
    <div class="general-page-content full-width">
      <div class="content">
        <div *ngIf="applicationNames && applicationNames.length" class="choose-platform-container">
            <p class="title" translate="login.IN_WHICH_AREA_ENTER"></p>
            <div class="full-width" fxLayout="row" fxLayoutAlign="space-between center">
                <div *ngFor="let appName of applicationNames" class="area-button" (click)="goToArea(appName.key)">
                    <p>{{appName.label}}</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
	<p translate="errors.NO_AVAILABLE_LANG"></p>
</div>