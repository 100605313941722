/*
* Componente che crea un placeholder per una lista generica
*/

import { Component, Input } from '@angular/core';
@Component({
  selector: 'generic-list-placeholder',
  templateUrl: './generic-list-placeholder.component.html',
  styleUrls: ['./generic-list-placeholder.component.scss']
})

export class GenericListPlaceholderComponent {
  @Input() containerClass: string;
  @Input() isForClustersTrainingPassport: boolean;
  constructor() {
  }
}