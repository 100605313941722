/*
 *    Dichiarazione dei moduli comuni e delle direttive
 */

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppTranslateModule } from "../translate/translate.module";
import { PaginationComponent } from "../core/pagination/pagination.component";
import { NgxPaginationModule } from "ngx-pagination";
import { MomentModule } from "ngx-moment";
import { SidebarModule } from "ng-sidebar";
import { TooltipModule } from "ng2-tooltip-directive";
import { AngularSvgIconModule } from "angular-svg-icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BaseComponent } from "./components/base-component.component";
import { BaseSubscriberComponent } from "./components/base-subscriber.component";
import { BaseFormComponent } from "./components/base-form.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { DisableFormControlDirective } from "./directive/disable-form-control.directive";
import { FileUploadModule } from "ng2-file-upload";
import { ModalComponent } from "./components/modal/modal.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { GenericListPlaceholderComponent } from "./components/placeholders/generic-list-placeholder/generic-list-placeholder.component";
import { CustomButtonComponent } from "./components/custom-button/custom-button.component";
import { CustomInputComponent } from "./components/input/input.component";
import { InputSearchComponent } from "./components/input-search/input-search.component";
import { ItemActionComponent } from "./components/item-action/item-action.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { MagicModalComponent } from "./components/magic-modal/magic-modal.component";
import { GaugeComponent } from "./components/gauge-indicator/gauge-indicator.component";
import { CompetenceBadge } from "./components/competence-badge/competence-badge.component";
import { PdfViewerModule } from "ng2-pdf-viewer";

@NgModule({
  declarations: [
    ModalComponent,
    PaginationComponent,
    BaseComponent,
    BaseSubscriberComponent,
    BaseFormComponent,
    DisableFormControlDirective,
    GenericListPlaceholderComponent,
    CustomButtonComponent,
    CustomInputComponent,
    InputSearchComponent,
    ItemActionComponent,
    SafeHtmlPipe,
    MagicModalComponent,
    GaugeComponent,
    CompetenceBadge
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FlexLayoutModule,
    AppTranslateModule.forRoot(),
    FileUploadModule,
    MomentModule,
    SidebarModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AngularSvgIconModule,
    TooltipModule,
    NgSelectModule,
    PdfViewerModule
  ],
  exports: [
    CommonModule,
    AppTranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FileUploadModule,
    PaginationComponent,
    ModalComponent,
    MomentModule,
    SidebarModule,
    BaseComponent,
    BaseSubscriberComponent,
    BaseFormComponent,
    DisableFormControlDirective,
    AngularSvgIconModule,
    TooltipModule,
    GenericListPlaceholderComponent,
    CustomButtonComponent,
    CustomInputComponent,
    InputSearchComponent,
    ItemActionComponent,
    NgSelectModule,
    SafeHtmlPipe,
    MagicModalComponent,
    GaugeComponent,
    CompetenceBadge
  ],
})
export class SharedModule { }
