<div class="badge-row-container">
  <ng-container *ngFor="let card of cards">
    <div fxLayout="row" fxLayoutAlign="start start">
    <div class="badge-wrapper" [ngClass]="{'active': card.isChecked}" (click)="emitOnCheck(card)">
      <svg-icon src="assets/img/badge-competence/{{card.id}}.svg"></svg-icon>
      <p *ngIf="card.id != 'HUMAN_TOUCH'">{{ card.id | titlecase }}</p>
      <p *ngIf="card.id == 'HUMAN_TOUCH'">Human touch</p>
    </div>
    <svg-icon (click)="onCompetenceTooltipClicked(card.id)" class="info-icon margin-left10" src="assets/img/icons/gear-informativa.svg">
    </svg-icon>
  </div>
  </ng-container>
</div>

<!-- Modale tooltip competenze - Innovation -->
<magic-modal [isAlleanza]="true" id="tooltip-modal5" inputClass="extended" [showBackButton]="true" imageSrc=""
  description="" actionLabel="" (onActionClicked)="false">
  <div class="inner-modal-body">
    <!-- PDF Viewer -->
    <pdf-viewer class="margin-top35 margin-bottom35 pdf-container" [src]="docLink" style="display: block;"
      [show-all]="false" [page]="5" [original-size]="false" [render-text]="true" [render-text-mode]="0" [zoom]="zoom">
    </pdf-viewer>
    <div class="margin-top16 margin-bottom8 full-width" fxLayout="column" fxLayoutAlign="center center">
      <div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <svg-icon class="zoom-buttons" src="assets/img/icons/minus.svg" (click)="zoomOut()"></svg-icon>
        <svg-icon class="zoom-buttons" src="assets/img/icons/plus.svg" (click)="zoomIn()"></svg-icon>
      </div>
    </div>
  </div>
</magic-modal>

<!-- Modale tooltip competenze - Ownership -->
<magic-modal [isAlleanza]="true" id="tooltip-modal2" inputClass="extended" [showBackButton]="true" imageSrc=""
  description="" actionLabel="" (onActionClicked)="false">
  <div class="inner-modal-body">
    <!-- PDF Viewer -->
    <pdf-viewer class="margin-top35 margin-bottom35 pdf-container" [src]="docLink" style="display: block;"
      [show-all]="false" [page]="2" [original-size]="false" [render-text]="true" [render-text-mode]="0" [zoom]="zoom">
    </pdf-viewer>
    <div class="margin-top16 margin-bottom8 full-width" fxLayout="column" fxLayoutAlign="center center">
      <div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <svg-icon class="zoom-buttons" src="assets/img/icons/minus.svg" (click)="zoomOut()"></svg-icon>
        <svg-icon class="zoom-buttons" src="assets/img/icons/plus.svg" (click)="zoomIn()"></svg-icon>
      </div>
    </div>
  </div>
</magic-modal>

<!-- Modale tooltip competenze - Human tuch -->
<magic-modal [isAlleanza]="true" id="tooltip-modal4" inputClass="extended" [showBackButton]="true" imageSrc=""
  description="" actionLabel="" (onActionClicked)="false">
  <div class="inner-modal-body">
    <!-- PDF Viewer -->
    <pdf-viewer class="margin-top35 margin-bottom35 pdf-container" [src]="docLink" style="display: block;"
      [show-all]="false" [page]="4" [original-size]="false" [render-text]="true" [render-text-mode]="0" [zoom]="zoom">
    </pdf-viewer>
    <div class="margin-top16 margin-bottom8 full-width" fxLayout="column" fxLayoutAlign="center center">
      <div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <svg-icon class="zoom-buttons" src="assets/img/icons/minus.svg" (click)="zoomOut()"></svg-icon>
        <svg-icon class="zoom-buttons" src="assets/img/icons/plus.svg" (click)="zoomIn()"></svg-icon>
      </div>
    </div>
  </div>
</magic-modal>


<!-- Modale tooltip competenze - Simplification -->
<magic-modal [isAlleanza]="true" id="tooltip-modal3" inputClass="extended" [showBackButton]="true" imageSrc=""
  description="" actionLabel="" (onActionClicked)="false">
  <div class="inner-modal-body">
    <!-- PDF Viewer -->
    <pdf-viewer class="margin-top35 margin-bottom35 pdf-container" [src]="docLink" style="display: block;"
      [show-all]="false" [page]="3" [original-size]="false" [render-text]="true" [render-text-mode]="0" [zoom]="zoom">
    </pdf-viewer>
    <div class="margin-top16 margin-bottom8 full-width" fxLayout="column" fxLayoutAlign="center center">
      <div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <svg-icon class="zoom-buttons" src="assets/img/icons/minus.svg" (click)="zoomOut()"></svg-icon>
        <svg-icon class="zoom-buttons" src="assets/img/icons/plus.svg" (click)="zoomIn()"></svg-icon>
      </div>
    </div>
  </div>
</magic-modal>


<!-- Modale tooltip competenze -->
<magic-modal [isAlleanza]="true" id="tooltip-modal1" inputClass="extended" [showBackButton]="true" imageSrc=""
  description="" actionLabel="" (onActionClicked)="false">
  <div class="inner-modal-body">
    <!-- PDF Viewer -->
    <pdf-viewer class="margin-top35 margin-bottom35 pdf-container" [src]="docLink" style="display: block;"
      [show-all]="false" [page]="1" [original-size]="false" [render-text]="true" [render-text-mode]="0" [zoom]="zoom">
    </pdf-viewer>
    <div class="margin-top16 margin-bottom8 full-width" fxLayout="column" fxLayoutAlign="center center">
      <div class="full-width" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <svg-icon class="zoom-buttons" src="assets/img/icons/minus.svg" (click)="zoomOut()"></svg-icon>
        <svg-icon class="zoom-buttons" src="assets/img/icons/plus.svg" (click)="zoomIn()"></svg-icon>
      </div>
    </div>
  </div>
</magic-modal>