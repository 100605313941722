import { Component, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import * as CoreActions from '../ngrx/core.actions';
import { Router } from '@angular/router';
import { JwtPayload, RequiredAuth } from "../../../cm2-commonclasses";
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, Subscription } from 'rxjs';
import { RedirectService } from '../../shared/services/redirect.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  loggedUser: JwtPayload;
  isMainMenuSidebarOpened: boolean;
  isProfileMenuSidebarOpened: boolean;
  result$: Subscription;
  @Output() activateAnimation = new EventEmitter();
  isAdmin: boolean;

  constructor(private store: Store<fromApp.AppState>,
    private router: Router,
    public translate: TranslateService,
    public redirectService: RedirectService,
    public authService: AuthService) {
    // Recupero le informazioni sull'utente loggato dallo Store applicativo
    let loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser);
    // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di sinistra col menu principale è aperta
    let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isMainMenuSidebarOpened);
    // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di destra col menu utente è aperta
    let isProfileMenuSidebarOpened$: Observable<boolean> = this.store.select(fromApp.isProfileMenuSidebarOpened);
    // Sto in ascolto dei vari cambiamenti
    const combinedSelectes$ = combineLatest(loggedUser$, isMainMenuSidebarOpened$, isProfileMenuSidebarOpened$);
    this.result$ = combinedSelectes$.subscribe(
      ([loggedUser, isOpened, isProfileMenuSidebarOpened]) => {
        this.loggedUser = loggedUser;
        this.isMainMenuSidebarOpened = isOpened;
        this.isProfileMenuSidebarOpened = isProfileMenuSidebarOpened;
      });
  }

  // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav nello Store
  closeMainMenuSidenav(): void {
    this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
  }

  // Verifica in quale pagina sono
  isThisCurrentPage(page: string) {
    if (
      page &&
      this.router &&
      this.router.url &&
      this.router.url.indexOf(page) !== -1
    ) {
      return true;
    }

    return false;
  }

  // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav nello Store
  closeProfileMenuSidenav(): void {
    this.store.dispatch(new CoreActions.CloseProfileMenuSidebar());
  }

  // Porta alla home page
  goToHome(): void {
    this.redirectService.goToHome();
  }

  goBack() {
    if (window.location.href.indexOf('myExperience/myExperienceHome') >= 0) {
      this.redirectService.goToHome()
    }
    else if (window.location.href.indexOf('myExperience/personasSurvey') >= 0) {
      this.redirectService.goToMyExperience();
    } else if (window.location.href.indexOf('myExperience/experiences/') >= 0) {
      window.history.back();
    } else if (window.location.href.indexOf('myExperience/competences/') >= 0) {
      window.history.back();
    } else if (window.location.href.indexOf('myExperience/personas/') >= 0) {
      window.history.back();
    } else if (window.location.href.indexOf('myExperience/personasProfileList') >= 0) {
      window.history.back();
    } else if (window.location.href.indexOf('myExperience/experiences') >= 0) {
      this.redirectService.goToMyExperience();
    } else if (window.location.href.indexOf('myExperience/competences') >= 0) {
      this.redirectService.goToMyExperience();
    } else if (window.location.href.indexOf('myExperience/personas') >= 0) {
      this.redirectService.goToMyExperience();
    } else {
      window.history.back();
    }
  }

  isValidPage() {
    return !(window.location.href.indexOf('users/home') >= 0) &&
      !(window.location.href.indexOf('nineboxmatrix') >= 0) &&
      !(window.location.href.indexOf('comparison') >= 0) &&
      !(window.location.href.indexOf('contributor/home') >= 0) &&
      !(window.location.href.indexOf('homeManage') >= 0) &&
      !(window.location.href.indexOf('hrbp/') >= 0) &&
      !(window.location.href.indexOf('admin') >= 0) &&
      !(window.location.href.indexOf('newEditExperience') >= 0) &&
      !(window.location.href.indexOf('newEditExperienceWithUserId') >= 0) &&
      !(window.location.href.indexOf('experienceDetail') >= 0)
  }

  // Dispatch dell'azione che esegue il toggle sulla sidebar di sinistra col menu principale
  toggleMainMenuSidebar(): void {
    // Alza l'evento per il componente padre, che attive le animazioni sulle sidenav
    this.activateAnimation.emit();
    if (!this.isMainMenuSidebarOpened) {
      this.store.dispatch(new CoreActions.OpenMainMenuSidebar());
    } else {
      this.closeMainMenuSidenav();
    }
  }

  // Dispatch dell'azione che esegue il toggle sulla sidebar di sinistra col profilo
  /* toggleProfileMenuSidebar(): void { */
  // Alza l'evento per il componente padre, che attiva le animazioni sulle sidenav
  /* this.activateAnimation.emit();
  if (!this.isProfileMenuSidebarOpened) {
      this.store.dispatch(new CoreActions.OpenProfileMenuSidebar());
  } else {
      this.closeProfileMenuSidenav();
  }
} */
}