/*
 * Modulo per la gestione dell'autenticazione
 */

import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { LoginComponent } from "./login/login.component";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LoginFormComponent } from "./login/login-form/login-form.component";
import { LocalLoginComponent } from "./login/local-login.component";
import { LoginSamlErrorComponent } from "./login/login-saml-error.component";
import { LogoutSsoCompletedComponent } from "./login/logout-sso-completed.component";
import { RedirectPageComponent } from "./login/redirect-page.component";
import { UtmCampaignComponent } from "./login/utm-campaign.component";
import { SwitchLoginComponent } from './login/switch-login.component';

@NgModule({
  declarations: [
    LoginComponent,
    LocalLoginComponent,
    RedirectPageComponent,
    UtmCampaignComponent,
    LoginSamlErrorComponent,
    LogoutSsoCompletedComponent,
    LoginFormComponent,
    SwitchLoginComponent
  ],
  imports: [FlexLayoutModule, CommonModule, FormsModule, TranslateModule]
})
export class AuthModule { }
