<div>
  <div fxLayout="column" class="theme-primary" fxLayoutAlign="start start">
    <div class="subheader-admin" fxLayout="row" fxLayoutAlign="start center">
      <div class="content margin-bottom0">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
          <div fxLayout="column" class="full-width" fxLayoutAlign="start start">
            <h1 class="title" translate="admin.HOME"></h1>
            <span class="subtitle" translate="admin.HOME_DESCRIPTION"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="space24"></div>

    <div class="content">
      <div class="full-width" fxLayout.gt-xs="row wrap" fxLayout.xs="column" fxLayoutAlign="start start"
        fxLayoutAlign.xs="center center" fxLayoutGap.gt-sm="24px grid" fxLayoutGap.sm="16px grid" fxLayoutGap.xs="16px">
        <div class="full-width" *ngFor="let menuButton of menuButtons" fxLayout="row" fxLayoutAlign="center center"
          fxFlex.gt-xs="50">
          <div class="button-admin-link">
            <button [ngClass]="{'disabled': menuButton.isDisabled}" (click)="goToPage(menuButton.id)" class="container"
              fxLayout="row">
              <div fxLayout="column" fxLayoutAlign="start start" class="full-width">
                <p class="title">{{menuButton.title}}</p>
                <p class="subtitle full-width">{{menuButton.subtitle}}</p>
              </div>
              <div fxLayout="row" fxLayoutAlign="end end">
                <svg-icon class="admin-icon" src="{{menuButton.icon}}"></svg-icon>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>