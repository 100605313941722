import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, switchMap, filter } from 'rxjs/operators';

import * as fromApp from '../../ngrx/app.reducers';
import { onceIfNotEmpty } from '../../ngrx/util';
import { from } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable()
export class SwitchLoginGuard implements CanActivate {
  constructor(private store: Store<fromApp.AppState>, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select(fromApp.isAuthenticated)
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        switchMap(() => {
          return this.store.select(fromApp.getLoggedUser);
        }),
        onceIfNotEmpty(),
        map(loggedUser => {
          if(loggedUser && loggedUser.user) {
            return true;
          } else {
            return false;
          }
        })
      )
  }
}