import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Lang } from "../../../cm2-commonclasses";
import { DeviceDetectorService } from 'ngx-device-detector';
import { UrlService } from '../../shared/services/url.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  isFetchingLangs: boolean;
  langs: Lang[];

  redirectUrl;

  constructor(private store: Store<fromApp.AppState>,
    public translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private urlService: UrlService,
    private router: Router,
    private route: ActivatedRoute) {
    let redirectUrl$: any = this.store.select(
      fromApp.getRedirectUrl
    );
    redirectUrl$.subscribe(redirectUrl => {
      this.redirectUrl = redirectUrl;
    });

    this.route.queryParams.subscribe(params => {
      // Potrebbe essere che ci sia una gestione delle campagne di Analytics, quindi avrò un url di questo tipo: https://performanceup.generali.it/#/?utm_source=employeeup&utm_medium=stories&utm_campaign=sollecito16marzo 
      const utm_source = params && params['utm_source'];
      const utm_medium = params && params['utm_medium'];
      const utm_campaign = params && params['utm_campaign'];
      const utm_term = params && params['utm_term'];
      const utm_content = params && params['utm_content'];

      const ssortkqp = params && params['ssortkqp'] && decodeURIComponent(params['ssortkqp']);
      // Potrebbe essere che l'utente stia accedendo al progetto con già direttamente il parametro nell'url, ad esempio https://performanceup.generali.it/#/?ssortkqp=XXX
      // potrebbe succedere ad esempio nel caso della employeeUP. Quindi in questo caso vado direttamente alla pagina redirect-page, dove c'è tutto
      // il flusso per fare il redirect
      if (ssortkqp) {
        this.router.navigate(['redirectPage'], { queryParams: { ssortkqp: ssortkqp, utm_source: utm_source, utm_medium: utm_medium, utm_campaign: utm_campaign, utm_term: utm_term, utm_content: utm_content } });
      } else {
        // Altrimenti vado all'SSO classico
        const deviceInfo = this.deviceService.getDeviceInfo();
        const userAgent = deviceInfo && deviceInfo.userAgent;
        let deviceType;
        if (this.deviceService.isMobile()) {
          // Salvo il fatto che è uno smartphone
          deviceType = 'P';
        } else if (this.deviceService.isTablet()) {
          // Salvo il fatto che è un tablet
          deviceType = 'T';
        } else if (this.deviceService.isDesktop()) {
          // Salvo il fatto che è un computer desktop
          deviceType = 'D';
        }
        // Ridireziono l'utente verso il sistema di SSO
        let redirectUrlAfterLogin = '/#/redirectPage';

        if (this.redirectUrl && this.redirectUrl.length) {
          redirectUrlAfterLogin = '/#' + this.redirectUrl;
        }

        let suffixForAnalyticsCampaing = '';
        // Per le campagne di google, solo i parametri utm_term e utm_content sono facoltativi, gli altri sono obbligatori
        if (utm_source) {
          suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "?utm_source=" + utm_source;

          if (utm_medium) {
            suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_medium=" + utm_medium;
          }

          if (utm_campaign) {
            suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_campaign=" + utm_campaign;
          }

          if (utm_term) {
            suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_term=" + utm_term;
          }

          if (utm_content) {
            suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_content=" + utm_content;
          }
        }

        // Aggiungo l'eventuale suffisso della campagna di Analytics
        redirectUrlAfterLogin = redirectUrlAfterLogin + suffixForAnalyticsCampaing;

        const redirectUrl = this.urlService.getSSOUrl(deviceType, userAgent, redirectUrlAfterLogin);
        window.location.href = redirectUrl;
      }
    });
  }
  ngOnInit() {
  }
  ngOnDestroy() {
  }
}
