import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gauge-indicator',
  templateUrl: 'gauge-indicator.component.html',
  styleUrls: ['./gauge-indicator.component.scss']
})
export class GaugeComponent implements OnInit {
  @Input() level: number; // 0-4
  @Input() size: string = 'small'; // big, small

  constructor() {
  }

  ngOnInit() { }
}