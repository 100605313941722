<ng-container *ngIf="size == 'small'">
  <div class="speedometer small level{{level}}">
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100" height="100" rx="50" fill="white" />
      <circle cx="50" cy="50" r="39" fill="#F6F6F6" />
      <path
        d="M53.0001 55.196C50.1304 56.8529 46.4608 55.8696 44.804 52.9999C43.1471 50.1301 44.1304 46.4606 47.0001 44.8037C49.8699 43.1469 53.5394 44.1301 55.1963 46.9999C56.8531 49.8696 55.8699 53.5392 53.0001 55.196Z"
        fill="#4D4D4D" />
      <path
        d="M14.2886 84.9956C7.39778 77.9639 2.73626 69.0543 0.888501 59.3841C-0.959262 49.7138 0.0889719 39.7132 3.90177 30.6363C7.71457 21.5593 14.1222 13.81 22.3213 8.35998C30.5204 2.90993 40.146 0.00172003 49.9913 7.62388e-07C59.8365 -0.00171851 69.4631 2.90313 77.6641 8.35031C85.8651 13.7975 92.2755 21.5446 96.0915 30.6202C99.9074 39.6958 100.959 49.696 99.1148 59.3669C97.2704 69.0378 92.612 77.949 85.7236 84.9832L81.0796 80.4353C87.0724 74.3156 91.1252 66.5629 92.7299 58.1492C94.3345 49.7355 93.4195 41.0353 90.0996 33.1396C86.7797 25.2438 81.2027 18.5038 74.0678 13.7648C66.9329 9.02572 58.5577 6.4985 49.9924 6.5C41.4271 6.5015 33.0528 9.03164 25.9196 13.7732C18.7863 18.5147 13.2117 25.2566 9.89454 33.1536C6.57741 41.0505 5.66544 49.751 7.273 58.1641C8.88055 66.5773 12.9361 74.3286 18.9311 80.4462L14.2886 84.9956Z"
        fill="url(#paint0_linear_4028_19247)" />
      <path
        d="M14.2886 84.9956C5.04906 75.5671 -0.0869034 62.8673 0.00111133 49.6666C0.089126 36.466 5.39397 23.8358 14.7584 14.5313L19.3398 19.1422C11.1928 27.2371 6.57754 38.2254 6.50097 49.71C6.42439 61.1946 10.8927 72.2434 18.9311 80.4462L14.2886 84.9956Z"
        fill="#BABABA" />
      <path class="level-indicator level0"
        d="M19.4463 72.6148L18.2991 70.9765L48.5665 47.9518L51.4344 52.0476L19.4463 72.6148Z" fill="#4D4D4D" />
      <path class="level-indicator level1"
        d="M16.5913 31.8658L17.5913 30.1337L51.2503 47.8347L48.7503 52.1648L16.5913 31.8658Z" fill="#4D4D4D" />
      <path class="level-indicator level2" d="M49 12H51L52.5 50H47.5L49 12Z" fill="#4D4D4D" />
      <path class="level-indicator level3"
        d="M82.4092 30.1338L83.4092 31.8658L51.2502 52.1648L48.7502 47.8347L82.4092 30.1338Z" fill="#4D4D4D" />
      <path class="level-indicator level4"
        d="M81.7016 70.9768L80.5545 72.6151L48.5664 52.0479L51.4342 47.9521L81.7016 70.9768Z" fill="#4D4D4D " />
      <defs>
        <linearGradient id="paint0_linear_4028_19247" x1="80.7" y1="73.5" x2="19.2" y2="73.5"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#71A841" />
          <stop offset="0.552" stop-color="#BCD960" />
          <stop offset="1" stop-color="#B9B9B9" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</ng-container>
<ng-container *ngIf="size != 'small'">
  <div class="speedometer level{{level}}">
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="100" height="100" rx="50" fill="white" />
      <circle cx="50" cy="50" r="39" fill="#F6F6F6" />
      <path
        d="M53.0001 55.196C50.1304 56.8529 46.4608 55.8696 44.804 52.9999C43.1471 50.1301 44.1304 46.4606 47.0001 44.8037C49.8699 43.1469 53.5394 44.1301 55.1963 46.9999C56.8531 49.8696 55.8699 53.5392 53.0001 55.196Z"
        fill="#4D4D4D" />
      <path
        d="M14.2886 84.9956C7.39778 77.9639 2.73626 69.0543 0.888501 59.3841C-0.959262 49.7138 0.0889719 39.7132 3.90177 30.6363C7.71457 21.5593 14.1222 13.81 22.3213 8.35998C30.5204 2.90993 40.146 0.00172003 49.9913 7.62388e-07C59.8365 -0.00171851 69.4631 2.90313 77.6641 8.35031C85.8651 13.7975 92.2755 21.5446 96.0915 30.6202C99.9074 39.6958 100.959 49.696 99.1148 59.3669C97.2704 69.0378 92.612 77.949 85.7236 84.9832L81.0796 80.4353C87.0724 74.3156 91.1252 66.5629 92.7299 58.1492C94.3345 49.7355 93.4195 41.0353 90.0996 33.1396C86.7797 25.2438 81.2027 18.5038 74.0678 13.7648C66.9329 9.02572 58.5577 6.4985 49.9924 6.5C41.4271 6.5015 33.0528 9.03164 25.9196 13.7732C18.7863 18.5147 13.2117 25.2566 9.89454 33.1536C6.57741 41.0505 5.66544 49.751 7.273 58.1641C8.88055 66.5773 12.9361 74.3286 18.9311 80.4462L14.2886 84.9956Z"
        fill="url(#paint0_linear_4028_19247)" />
      <path
        d="M14.2886 84.9956C5.04906 75.5671 -0.0869034 62.8673 0.00111133 49.6666C0.089126 36.466 5.39397 23.8358 14.7584 14.5313L19.3398 19.1422C11.1928 27.2371 6.57754 38.2254 6.50097 49.71C6.42439 61.1946 10.8927 72.2434 18.9311 80.4462L14.2886 84.9956Z"
        fill="#BABABA" />
      <path class="level-indicator level0"
        d="M19.4463 72.6148L18.2991 70.9765L48.5665 47.9518L51.4344 52.0476L19.4463 72.6148Z" fill="#4D4D4D" />
      <path class="level-indicator level1"
        d="M16.5913 31.8658L17.5913 30.1337L51.2503 47.8347L48.7503 52.1648L16.5913 31.8658Z" fill="#4D4D4D" />
      <path class="level-indicator level2" d="M49 12H51L52.5 50H47.5L49 12Z" fill="#4D4D4D" />
      <path class="level-indicator level3"
        d="M82.4092 30.1338L83.4092 31.8658L51.2502 52.1648L48.7502 47.8347L82.4092 30.1338Z" fill="#4D4D4D" />
      <path class="level-indicator level4"
        d="M81.7016 70.9768L80.5545 72.6151L48.5664 52.0479L51.4342 47.9521L81.7016 70.9768Z" fill="#4D4D4D " />
      <defs>
        <linearGradient id="paint0_linear_4028_19247" x1="80.7" y1="73.5" x2="19.2" y2="73.5"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#71A841" />
          <stop offset="0.552" stop-color="#BCD960" />
          <stop offset="1" stop-color="#B9B9B9" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</ng-container>