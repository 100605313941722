<div class="timeline-item no-overflow" [ngClass]="containerClass">
  <div class="animated-background facebook">
    <div [ngClass]="{'background-clusters': isForClustersTrainingPassport}" class="background-masker header-top"></div>
    <div class="background-masker header-left"></div>
    <div class="background-masker header-right"></div>
    <div [ngClass]="{'background-clusters': isForClustersTrainingPassport}" class="background-masker header-bottom">
    </div>
    <div class="background-masker subheader-left"></div>
    <div class="background-masker subheader-right"></div>
    <div [ngClass]="{'background-clusters': isForClustersTrainingPassport}" class="background-masker subheader-bottom">
    </div>
  </div>
</div>