import { Observable, ObservableInput } from "rxjs";
import { switchMap, take, filter } from "rxjs/operators";
export function switchOnce<T, R>(project: (value: T, index?: number) => ObservableInput<R>) {
  return function switchOnceImplementation(source: Observable<T>): Observable<R> {
    return source.pipe(take(1), switchMap(project))
  }
}
export function onceIfNotEmpty<T>(project?: (value: T, index?: number) => ObservableInput<T>) {
  return function switchOnceImplementation(source: Observable<T>): Observable<T> {
    return source.pipe(filter(val => !!val), take(1));
  }
}

// Controlla se due array di oggetti sono uguali
export function areArrayOfObjectsEquals(value, other) {
  const type = Object.prototype.toString.call(value);

  // Gli oggetti non sono dello stesso tipo
  if (type !== Object.prototype.toString.call(other)) return false;

  // Torno false se non è un array di oggetti
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Verfico la lunghezza
  const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Comparo gli oggetti cotenuti
  const compare = (item1, item2) => {
    const itemType = Object.prototype.toString.call(item1);

    // Oggetto o array
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!areArrayOfObjectsEquals(item1, item2)) return false;
    }
    else {
      // Gli oggetti non sono dello stesso tipo
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }

    }
  };

  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // Oggetti uguali
  return true;

};