import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ErrorPageComponent } from './core/error/error-page.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { CompatibilityGuard } from './shared/services/compatibility-guard.service';
import { LoginSamlErrorComponent } from './auth/login/login-saml-error.component';
import { LogoutSsoCompletedComponent } from './auth/login/logout-sso-completed.component';
import { CompatibilityErrorComponent } from './core/compatibility-error/compatibility-error.component';
import { LocalLoginComponent } from './auth/login/local-login.component';
import { LoginComponent } from './auth/login/login.component';
import { LoginGuard } from './shared/services/login-guard.service';
import { PageNotAuthorizedComponent } from './core/page-not-authorized/page-not-authorized.component';
import { RedirectPageComponent } from './auth/login/redirect-page.component';
import { UtmCampaignComponent } from './auth/login/utm-campaign.component';
import { SwitchLoginComponent } from './auth/login/switch-login.component';
import { SwitchLoginGuard } from './shared/services/switch-login-guard.component';
import { AuthGuard } from './auth/services/auth-guard.service';

const appRoutes: Routes = [
  { path: 'switchLogin', component: SwitchLoginComponent, canActivate: [AuthGuard, CompatibilityGuard] },
  { path: 'redirectPage', component: RedirectPageComponent, canActivate: [CompatibilityGuard] },
  { path: 'utmCampaign', component: UtmCampaignComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'localLogin', component: LocalLoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'loginSamlError', component: LoginSamlErrorComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'logoutSsoCompleted', component: LogoutSsoCompletedComponent, canActivate: [LoginGuard, CompatibilityGuard] },
  { path: 'compatibilityError', component: CompatibilityErrorComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '403', component: PageNotAuthorizedComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }