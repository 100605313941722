import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { UrlService } from '../../shared/services/url.service';
import { Observable, combineLatest } from 'rxjs';
import { Lang, JwtPayload, CommonConstants } from 'atfcore-commonclasses';
import { authControl } from '../../shared/models/global-application-data.model';
import { onceIfNotEmpty } from '../../ngrx/util';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-switch-login',
  templateUrl: './switch-login.component.html',
  styleUrls: ['./switch-login.component.scss']
})
export class SwitchLoginComponent implements OnInit, OnDestroy {
  redirectUrl;
  isFetchingLangs: boolean;
  langs: Lang[];
  result$;
  loggedUser: JwtPayload;
  authObject;
  applicationContext: string;

  isLoadingAppNames: boolean;
  applicationNames: any[] = [];
  firstTimeCallAppNames = true;

  constructor(private store: Store<fromApp.AppState>,
    private urlService: UrlService,
    private authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,) {
    // Sto in ascolto di quando il globalApplicationData cambia, così da sapere quando ci sono le lingue disponibili poiché, se non ci fossero, il pulsante per il login sarebbe disabilitato
    let availableLangs$: Observable<Lang[]> = this.store.select(fromApp.getAvailableLangs);
    let isFetchingLangs$: Observable<boolean> = this.store.select(fromApp.isFetchingLangs);
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser).pipe(onceIfNotEmpty());
    const combinedSelectes$ = combineLatest(availableLangs$, isFetchingLangs$, loggedUser$);
    this.result$ = combinedSelectes$.subscribe(
      ([langs, isFetchingLangs, loggedUser]) => {
        this.langs = langs;
        this.isFetchingLangs = isFetchingLangs;
        this.loggedUser = loggedUser;

        if (this.loggedUser && this.loggedUser.auths && this.loggedUser.auths.length && this.firstTimeCallAppNames) {
          this.firstTimeCallAppNames = false;
          this.authObject = authControl(this.loggedUser.auths);
          this.getAvailableAppNames();
        }
      });
  }

  ngOnDestroy() {
    this.result$.unsubscribe();
  }

  ngOnInit() {
    this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
  }

  goToClaimsArea() {
    if (this.authObject.isManager) {
      window.location.href = this.applicationContext + 'claims/#/users';
    } else {
      window.location.href = this.applicationContext + 'claims/#/contributor';
    }
  }

  goToGeneraliArea() {
    if (this.authObject.isManager) {
      window.location.href = this.applicationContext + 'gi/#/users';
    } else {
      window.location.href = this.applicationContext + 'gi/#/contributor';
    }
  }

  goToGenertelArea() {
    if (this.authObject.isManager) {
      window.location.href = this.applicationContext + 'gt/#/users';
    } else {
      window.location.href = this.applicationContext + 'gt/#/contributor';
    }
  }

  goToDasArea() {
    if (this.authObject.isManager) {
      window.location.href = this.applicationContext + 'das/#/users';
    } else {
      window.location.href = this.applicationContext + 'das/#/contributor';
    }
  }

  goToAlleanzaArea() {
    if (this.authObject.isManager) {
      window.location.href = this.applicationContext + 'alleanza/#/users';
    } else {
      window.location.href = this.applicationContext + 'alleanza/#/contributor';
    }
  }

  goToArea(appKey?: string) {
    if (appKey == CommonConstants.APPLICATION_PERFORMANCEMANAGEMENT) {
      this.goToGeneraliArea();
    } else if (appKey == CommonConstants.APPLICATION_PERFORMANCEMANAGEMENT_CLAIMS) {
      this.goToClaimsArea();
    } else if (appKey == CommonConstants.APPLICATION_PERFORMANCEMANAGEMENT_GENERTEL) {
      this.goToGenertelArea();
    } else if (appKey == CommonConstants.APPLICATION_PERFORMANCEMANAGEMENT_DAS) {
      this.goToDasArea();
    } else if (appKey == CommonConstants.APPLICATION_PERFORMANCEMANAGEMENT_ALLEANZA) {
      this.goToAlleanzaArea();
    }
  }

  getAvailableAppNames() {
    this.isLoadingAppNames = true;

    this.authService.getAvailableAppsWithoutToken()
      .subscribe((data) => {
        if (data && data.error) {
          this.toastr.error(this.translate.instant('errors.' + data.error));
          this.isLoadingAppNames = false;
        } else {
          if (data.response && data.response.length && Array.isArray(data.response)) {
            for (let i = 0; i < data.response.length; i++) {
              let appName = data.response[i];
              this.applicationNames.push(
                {
                  label: this.translate.instant("login.ACCESS_TO") + this.translate.instant("login." + appName),
                  key: appName
                }
              )
            }
          }
          this.isLoadingAppNames = false;
        }
      }, (err) => {
        this.toastr.error(this.translate.instant('errors.' + err));
        this.isLoadingAppNames = false;
      })
  }
}
