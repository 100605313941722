import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})

export class InputSearchComponent {
  @Input() placeholder: string;
  @Input() bindValue: string;
  @Input() disabled: boolean;
  @Input() fromNinebox: boolean;
  @Input() noMarginTop: boolean;
  @Output() onStartSearch = new EventEmitter();
  @Output() onSearchedTextUpdated = new EventEmitter();
  @Input() customContainerClass: string;

  constructor() {
  }

  updateSearchedText(text) {
    this.onSearchedTextUpdated.emit(text);
  }

  // Torna la lista di classi del contenitore
  getContainerClass() {
    let classList = '';

    if (this.fromNinebox) {
      classList = classList + 'from-ninebox ';
    }
    if (this.noMarginTop) {
      classList = classList + 'no-margin-top ';
    }
    if (this.customContainerClass && this.customContainerClass.length) {
      classList = classList + this.customContainerClass;
    }

    return classList;
  }

  onSubmit() {
    this.onStartSearch.emit(this.bindValue);
  }
}