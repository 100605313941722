<div class="modal" [ngClass]="inputClass">
  <div #modalEl class="modal-body" [ngClass]="{'overflow-hidden': fromCascadingWhatModal}" tabindex="-1" aria-modal="true" role="dialog">
    <div class="inner-modal-close" *ngIf="showBackButton">
      <svg-icon id="close-icon-modal" tabindex="0" src="assets/img/icons/icon-close.svg" tabindex="0" (click)="close()">
      </svg-icon>
    </div>
    <div *ngIf="showSecondBackButton" class="full-width margin-left20 margin-top15" fxLayout="row" fxLayoutAlign="start start">
      <button fxLayoutAlign="center center" (click)="close()">
        <svg-icon class="back-icon-modal" src="assets/img/icons/arrow-left.svg"></svg-icon>
        <span tabindex="0" class="back-text" translate="feedback.BACK"></span>
      </button>
    </div>
    <div class="inner-modal-header" [ngClass]="{'min-height190': fromCascadingWhatModal}" *ngIf="title || imageSrc || description">
      <svg-icon *ngIf="imageSrc" [src]="imageSrc"></svg-icon>
      <h2 [ngClass]="{'from-cascading-what-modal': fromCascadingWhatModal}" tabindex="1">{{ title }}</h2>
      <!-- Mostro una descrizione testuale o l'ng-content -->
      <p [ngClass]="{'from-cascading-what-modal': fromCascadingWhatModal}" *ngIf="!useNgContent; else ngContentTemplate" tabindex="2" class="descripiton" [innerHTML]="description"></p>
      <ng-template #ngContentTemplate>
        <ng-content></ng-content>
      </ng-template>
    </div>
    <ng-content tabindex="3" select=".inner-modal-body"></ng-content>
    <!-- Azioni, se ci sono -->
    <div class="full-width">
      <ng-container *ngIf="secondaryActionLabel || actionLabel">
        <div tabindex="4" class="inner-modal-actions">
          <ng-container *ngIf="secondaryActionLabel">
            <custom-button [isAlleanza]="isAlleanza" class="full-width" dimension="full-width" importance="secondary"
              [text]="secondaryActionLabel" fxLayout="row" fxLayoutAlign="center center"
              [disabled]="secondaryActionDisabled" (onButtonClicked)="emitOnSecondaryActionClicked($event)">
            </custom-button>
          </ng-container>
          <ng-container *ngIf="actionLabel">
            <custom-button [isAlleanza]="isAlleanza" class="full-width" dimension="full-width" importance="primary"
              [text]="actionLabel" fxLayout="row" fxLayoutAlign="center center" [disabled]="actionDisabled"
              (onButtonClicked)="emitOnActionClicked($event)">
            </custom-button>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="textActionLabel">
        <div class="inner-modal-actions link" [ngClass]="isAlleanza ? 'alleanza' : ''">
          <a (click)="emitOnTextActionClicked()">{{ textActionLabel }}</a>
        </div>
      </ng-container>
    </div>
  </div>
</div>