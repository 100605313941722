import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromProfile from '../users/profile/ngrx/profile.reducers';
import * as fromAuth from '../auth/ngrx/auth.reducers';
import * as fromCore from '../core/ngrx/core.reducers';

export interface AppState {
  user: fromProfile.ProfileState;
  auth: fromAuth.AuthState;
  core: fromCore.CoreState;
}

export const reducers: ActionReducerMap<AppState> = {
  user: fromProfile.profileReducer,
  auth: fromAuth.authReducer,
  core: fromCore.coreReducer
};

export const getAuthAppState = createFeatureSelector<fromAuth.AuthState>('auth');
export const getCoreAppState = createFeatureSelector<fromCore.CoreState>('core');
export const getProfileAppState = createFeatureSelector<fromProfile.ProfileState>('user');

export const getAuthState = createSelector(
  getAuthAppState,
  (state: fromAuth.AuthState) => state
);
export const getToken = createSelector(
  getAuthState,
  fromAuth.getToken
);
export const getUserAcknowledges = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.userAcknowledges
);

// Selettori relativi allo state del core
export const getCoreState = createSelector(
  getCoreAppState,
  (state: fromCore.CoreState) => state
);
export const isAuthenticated = createSelector(
  getAuthState,
  (state: fromAuth.AuthState) => state.authenticated
);
export const isFetchingLangs = createSelector(
  getCoreAppState,
  fromCore.isFetchingLangs
);
export const getRedirectUrl = createSelector(
  getCoreAppState,
  fromCore.getRedirectUrl
);
export const getApplicationContext = createSelector(
  getCoreAppState,
  fromCore.getApplicationContext
);
export const getGlobalApplicationData = createSelector(
  getCoreAppState,
  fromCore.getGlobalApplicationData
);
export const showApplicationLoader = createSelector(
  getCoreAppState,
  fromCore.showApplicationLoader
);
export const isMainMenuSidebarOpened = createSelector(
  getCoreAppState,
  fromCore.isMainMenuSidebarOpened
);
export const getApplicationLang = createSelector(
  getCoreAppState,
  fromCore.getApplicationLang
);
export const getDefaultLang = createSelector(
  getCoreAppState,
  fromCore.getDefaultLang
);
export const isProfileMenuSidebarOpened = createSelector(
  getCoreAppState,
  fromCore.isProfileMenuSidebarOpened
);

export const getProfileState = createSelector(
  getProfileAppState,
  (state: fromProfile.ProfileState) => state
);
export const getLoggedUser = createSelector(
  getProfileState,
  fromProfile.getLoggedUser
);
export const getAvailableLangs = createSelector(
  getProfileState,
  fromProfile.getAvailableLangs
);
export const getNotificationsCounter = createSelector(
  getCoreAppState,
  fromCore.getNotificationsCounter
);

export const getPhasesInfo = createSelector(
  getCoreAppState,
  fromCore.getPhasesInfo
);

export const isFetchingPhasesInfo = createSelector(
  getCoreAppState,
  fromCore.isFetchingPhasesInfo
);

export const getIsImpersonification = createSelector(
  getCoreAppState,
  fromCore.getIsImpersonification
);

export const getAskedCountCompetences = createSelector(
  getCoreAppState,
  fromCore.getAskedCountCompetences
);
export const isFetchingNumberAskedCompetences = createSelector(
  getCoreAppState,
  fromCore.isFetchingAskedCountCompetences
);


export const getCurrentYear = createSelector(
  getCoreAppState,
  fromCore.getCurrentYear
)