import { Component, OnInit } from '@angular/core';
import { BaseSubscriberComponent } from '../../shared/components/base-subscriber.component';
import { RedirectService } from '../../shared/services/redirect.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest } from 'rxjs';
import { JwtPayload } from 'atfcore-commonclasses';
import { onceIfNotEmpty } from 'projects/gi/src/app/ngrx/util';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { authControl } from 'projects/gi/src/app/shared/models/global-application-data.model';
import { Router } from '@angular/router';
import * as AuthActions from "../../auth/ngrx/auth.actions";
import * as CoreActions from "../../core/ngrx/core.actions";
import * as ProfileActions from "../../users/profile/ngrx/profile.actions";

@Component({
  selector: 'app-home-redirect-token',
  templateUrl: './home-redirect-token.component.html',
  styleUrls: ['./home-redirect-token.component.scss']
})
export class HomeRedirectTokenComponent extends BaseSubscriberComponent implements OnInit {
  authObject;

  constructor(
    public redirectService: RedirectService,
    public translate: TranslateService,
    private router: Router,
    private store: Store<fromApp.AppState>) {
    super();
  }

  ngOnInit() {

    this.store.dispatch(new AuthActions.LogoutForImpersonificate());
    this.store.dispatch(new AuthActions.SetUserAcknowledges(null));
    this.store.dispatch(new CoreActions.RemoveApplicationLang());
    this.store.dispatch(new ProfileActions.CancelLoggedUser());
    this.store.dispatch(new CoreActions.SetImpersonificationBanner(true));

    const applicationLang$: Observable<string> = this.store.select(fromApp.getApplicationLang).pipe(onceIfNotEmpty());
    const loggedUser$: Observable<JwtPayload> = this.store.select(fromApp.getLoggedUser).pipe(onceIfNotEmpty());

    combineLatest(applicationLang$, loggedUser$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([applicationLang, loggedUser]) => {
          if (applicationLang && loggedUser) {
            this.authObject = authControl(loggedUser && loggedUser.auths);
            if (this.authObject.isHRBP || this.authObject.isAdmin) {
              this.router.navigate(["homeManage"]);
            } else if (this.authObject.isManager) {
              this.router.navigate(["/users/home"]);
            } else {
              this.router.navigate(["contributor"]);
            }
          }
        })
  }

  ngOnDestroy() {

  }

}