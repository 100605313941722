<div class="modal"
  [ngClass]="[isModalBadge ? 'badge-modal' : '', isExperienceVideoModal ? 'experience-video' : '', isFilterComparisonModal ? 'filter-comparison' : '']">
  <div #modalEl class="modal-body"
    [ngClass]="{'padding0': noPadding, 'max-width-450': isNewCompetenceMyExp, 'baseline-body': baselineModal, 'design-2-be-dev-area-modal': isDesign2BeDevAreaModal, 'design-2-be-video-tutorial-modal': isDesign2BeTutorialVideoModal}"
    tabindex="-1" aria-modal="true" role="dialog">
    <div [ngClass]="historyModal ? 'history-modal' : ''" class="back-button-container" *ngIf="showBackButton"
      fxLayout="row" fxLayoutAlign="space-between center">
      <button class="back-button" tabindex="0" (click)="close()">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
          <svg-icon src="assets/img/icons/arrow-left.svg"></svg-icon>
          <p translate="generic.BACK"></p>
        </div>
      </button>

      <button attr.aria-label="{{'generic.OPEN_MODAL' | translate}}" (click)="openModalInfoIcon()"
        *ngIf="isExperienceNewCompetenceModal && infoIcon" tabindex="0">
        <svg-icon class="info-icon" src="assets/img/icons/gear-informativa.svg"></svg-icon>
      </button>
    </div>
    <ng-content></ng-content>
  </div>
</div>

<!--div class="modal">
  <div #modalEl
       attr.aria-label="{{'generic.MODAL_CONTENT' | translate}}"
       class="modal-body"
       aria-modal="true" role="dialog">
    <ng-content></ng-content>
  </div>
</div>
<div class="modal-background"></div-->