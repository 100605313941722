<form class="login-wrapper" (ngSubmit)="login(f)" #f="ngForm">
  <!-- Email -->
  <div fxLayout="row" fxFlexFill fxLayoutAlign="center center">
    <div class="generali-input small">
      <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
        <h5 translate="generic.EMAIL"></h5>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <input class="full-width" type="email" id="email"
          placeholder="{{'accessibilityAttributes.INSERT_MAIL' | translate}}"
          attr.aria-label="{{'accessibilityAttributes.INSERT_MAIL' | translate}}" name="email" #email="ngModel" ngModel
          required email>
      </div>
      <!-- Errore di email non valida -->
      <div *ngIf="!email.valid && email.touched" class="input-error" fxLayout="row" fxLayoutAlign="start center">
        <span translate="errors.EMAIL_NOT_VALID"></span>
      </div>
    </div>
  </div>

  <div class="space15"></div>

  <!-- Password -->
  <div Password="row" fxFlexFill fxLayoutAlign="center center">
    <div class="generali-input small">
      <div class="title-input" fxLayout="row" fxLayoutAlign="start start" fxFlexFill>
        <h5 translate="generic.PASSWORD"></h5>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center">
        <input class="full-width" type="password" id="password"
          placeholder="{{'accessibilityAttributes.INSERT_PASSWORD' | translate}}"
          attr.aria-label="{{'accessibilityAttributes.INSERT_PASSWORD' | translate}}" name="password" ngModel
          #password="ngModel" required>
      </div>

      <!-- Password non inserita -->
      <div *ngIf="!password.valid && password.touched" class="input-error" fxLayout="row" fxLayoutAlign="start center">
        <span translate="errors.TYPE_PASSWORD"></span>
      </div>
    </div>
  </div>

  <!-- Pulsante per il login -->
  <div fxLayout="row" fxFlexFill fxLayoutAlign="center end">
    <button class="btn-primary-1 large margin-top40 margin-bottom30" type="submit" [disabled]="!f.valid" role="button">
      <p translate="login.SIGN_IN"></p>
    </button>
  </div>
</form>