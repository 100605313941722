import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "item-action",
  templateUrl: "./item-action.component.html",
  styleUrls: ["./item-action.component.scss"],
})
export class ItemActionComponent implements OnInit {
  @Input() iconLeft: string;
  @Input() title: string;
  @Input() required: boolean;
  @Input() isEdit: boolean;
  @Input() hasTooltip: boolean;
  @Input() hide: boolean;
  @Input() isPast: boolean;
  @Input() hasCategoryTooltip: boolean;
  @Output() onTooltipClicked = new EventEmitter();
  @Output() onTooltipCategoryClicked = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  toggleTooltip(data) {
    this.onTooltipClicked.emit(data);
  }

  toggleCategoryTooltip(data) {
    this.onTooltipCategoryClicked.emit(data);
  }
}
