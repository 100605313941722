import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PerformanceManagementStakeHolderRequestBadge } from 'atfcore-commonclasses';
import { MagicModalService } from '../magic-modal/magic-modal.service';

@Component({
  selector: 'competence-badge',
  templateUrl: 'competence-badge.component.html',
  styleUrls: ['./competence-badge.component.scss']
})
export class CompetenceBadge implements OnInit {
  cards = [
    {
      id: PerformanceManagementStakeHolderRequestBadge.INNOVATION,
      isChecked: false
    },
    {
      id: PerformanceManagementStakeHolderRequestBadge.OWNERSHIP,
      isChecked: false
    },
    {
      id: PerformanceManagementStakeHolderRequestBadge.HUMAN_TOUCH,
      isChecked: false
    },
    {
      id: PerformanceManagementStakeHolderRequestBadge.SIMPLIFICATION,
      isChecked: false
    }
  ]
  
  @Input() selectedBadges: string;
  @Output() onCheck: EventEmitter<any> = new EventEmitter<any>();

  zoom;
  pdfPage;
  docLink = 'assets/pdf/Lifetime Partner Behaviours_Competency Model - ITA_V6.pdf'

  constructor(private magicModalService: MagicModalService) {
  }

  emitOnCheck(card) {
    /* this.cards.forEach((x: any) => {
      x.isChecked = false;
    }) */
    card.isChecked = !card.isChecked;
    let cardsSelected = this.cards.filter((x: any) => {
      return x.isChecked == true;
    })
    this.onCheck.emit(cardsSelected);
  }

  ngOnInit() {
    if (this.selectedBadges && this.selectedBadges.length) {
      let selectedCards = [];
      for (let q = 0; q < this.cards.length; q++) {
        let card = this.cards[q];
        for (let i = 0; i < this.selectedBadges.length; i++) {
          let selectedBadge = this.selectedBadges[i];
          if (card.id == selectedBadge) {
            selectedCards.push(card);
          }
        }
      }

        if (selectedCards && selectedCards.length) {
          selectedCards.forEach((x: any) => {
            x.isChecked = true;
          })
          this.onCheck.emit(selectedCards);
        }
      }
    }

    onCompetenceTooltipClicked(title) {
      this.zoom = 0.8;
      if (title == 'OWNERSHIP') {
        this.pdfPage = 2;
      } else if (title == 'SIMPLIFICATION') {
        this.pdfPage = 3;
      } else if (title == 'HUMAN_TOUCH') {
        this.pdfPage = 4;
      } else if (title == 'INNOVATION') {
        this.pdfPage = 5;
      } else {
        this.pdfPage = 1;
      }
      this.magicModalService.open('tooltip-modal' + this.pdfPage);
    }
  
    zoomOut() {
      this.zoom -= 0.1;
    }
  
    zoomIn() {
      this.zoom += 0.1;
    }
  }