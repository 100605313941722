import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from '../../ngrx/app.reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { UrlService } from '../../shared/services/url.service';
import { doApplicationNameRedirect } from 'src/app/ngrx/util';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
// Importo il componente per decodificare il token JWT
const jwtDecode = require("jwt-decode");

@Component({
  selector: 'app-redirect-page',
  templateUrl: './redirect-page.component.html'
})
export class RedirectPageComponent {
  redirectUrl;

  constructor(private store: Store<fromApp.AppState>,
    private urlService: UrlService,
    private router: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private route: ActivatedRoute) {
    let redirectUrl$: any = this.store.select(
      fromApp.getRedirectUrl
    );
    redirectUrl$.subscribe(redirectUrl => {
      this.redirectUrl = redirectUrl;
    });

    this.route.queryParams.subscribe(params => {
      // Potrebbe essere che ci sia una gestione delle campagne di Analytics, quindi avrò un url di questo tipo: https://performanceup.generali.it/#/?utm_source=employeeup&utm_medium=stories&utm_campaign=sollecito16marzo 
      const utm_source = params && params['utm_source'];
      const utm_medium = params && params['utm_medium'];
      const utm_campaign = params && params['utm_campaign'];
      const utm_term = params && params['utm_term'];
      const utm_content = params && params['utm_content'];

      const ssortkqp = params && params['ssortkqp'] && decodeURIComponent(params['ssortkqp']);
      // Se ho l'ssortkqp significa che sto tornando dal login sso, quindi sono nell'url https://performanceup.generali.it/?ssortkqp=64f64619f308422c9a4a935979b8d265
      // Pertanto devo recuperare il token contempleto dell'utente per fare il redirect
      if (ssortkqp) {
        const getTokenFromKeyPromise = this.getTokenFromSsortkqp(ssortkqp);
        getTokenFromKeyPromise.then((token: string) => {
          if (token && token.length) {
            // Salvo il tiny token nel session storage
            sessionStorage.setItem("token", token);
            // Recupero il token completo
            const getFullTokenPromise = this.getFullToken(token);
            getFullTokenPromise.then((fullToken: any) => {
              if (fullToken.error) {
                this.toastr.error(fullToken.error);
                this.router.navigateByUrl('/403');
              } else if (fullToken.response) {
                const decodedJwt = jwtDecode(fullToken.response);

                let suffixForAnalyticsCampaing = '';
                // Per le campagne di google, solo i parametri utm_term e utm_content sono facoltativi, gli altri sono obbligatori
                if (utm_source) {
                  // Devo aggiungere uno slash iniziale, altrimenti andrà nella pagina xxx/gi/#?utm_source ecc
                  suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "?utm_source=" + utm_source;

                  if (utm_medium) {
                    suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_medium=" + utm_medium;
                  }

                  if (utm_campaign) {
                    suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_campaign=" + utm_campaign;
                  }

                  if (utm_term) {
                    suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_term=" + utm_term;
                  }

                  if (utm_content) {
                    suffixForAnalyticsCampaing = suffixForAnalyticsCampaing + "&utm_content=" + utm_content;
                  }
                }

                const redirectToPage = (this.redirectUrl || '');

                const getApplicationNamePromise = this.getAvailableAppsUser(token);
                getApplicationNamePromise.then((data: any) => {
              if (data.error) {
                this.toastr.error(data.error);
                this.router.navigateByUrl('/403');
              } else if (data.response) {
                doApplicationNameRedirect(data.response, decodedJwt, this.urlService, this.router, redirectToPage, suffixForAnalyticsCampaing);
              } else {
                return this.router.navigateByUrl('/403');
              }
            })
                /* doTenantRedirect(decodedJwt, this.urlService, this.router, redirectToPage, suffixForAnalyticsCampaing); */
              } else {
                // Token non trovato, vado alla pagina 403
                return this.router.navigateByUrl('/403');
              }
            });
          } else {
            // Token non valido, vado alla pagina 403
            return this.router.navigateByUrl('/403');
          }
        }, (e?) => {
          this.toastr.error(e.error);
          this.router.navigateByUrl('/403');
        })
      }
    });
  }

  // Recupera il token copmleto
  getFullToken(key: string) {
    return new Promise((resolve, reject) => {
      this.getJWTToken(key).subscribe((senecaResponse) => {
        resolve(senecaResponse);
      },
        (err) => {
          this.toastr.error(err);
          reject(err);
        })
    })
  }

  getAvailableAppsUser(token: string) {
    return new Promise((resolve, reject) => {
      this.getAvailableApps(token).subscribe((senecaResponse) => {
        resolve(senecaResponse);
      },
        (err) => {
          this.toastr.error(err);
          reject(err);
        })
    })
  }

  // Recupera token dalla chiave dell'url
  getTokenFromSsortkqp(key: string) {
    return new Promise((resolve, reject) => {
      this.retrieveTokenAfterLogin(key).subscribe((senecaResponse) => {
        if (senecaResponse.error) {
          reject(senecaResponse);
        } else {
          if (senecaResponse && senecaResponse.response) {
            resolve(senecaResponse.response);
          } else {
            resolve(null);
          }
        }
      },
        (err) => {
          reject(err);
        })
    })
  }

  // Servizio che recupera il JWT token dato un tiny token
  getJWTToken(token: string): any {
    const applicationContext = this.urlService.getApplicationUrl().baseUrl;
    return this.http.get<any>(applicationContext + 'rest-api/performancemanagement2022-mediator/get-full-jwt-token', { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  // Torna gli applicationName dell'utente
  getAvailableApps(token: string) {
    const applicationContext = this.urlService.getApplicationUrl().baseUrl;
    return this.http.get<any>(applicationContext + 'rest-api/performancemanagement2022-mediator/get-my-available-applications', { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  // Ritrova il token dall'ssortkqp
  retrieveTokenAfterLogin(retrieveKey: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('retrieveKey', retrieveKey);
    const applicationContext = this.urlService.getApplicationUrl().baseUrl;
    return this.http.get<any>(applicationContext + 'rest-api/performancemanagement2022-mediator/retrieve-token-after-login', {
      params: httpParams
    });
  }
}
