import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { ActivatedRoute, Router } from "@angular/router";
import { map, tap, switchMap, mergeMap } from "rxjs/operators";
import { from, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import * as CoreActions from "../../core/ngrx/core.actions";
import { Store } from "@ngrx/store";
import * as AuthActions from "./auth.actions";
import { AuthService } from "../services/auth.service";
import { ToastrService } from "ngx-toastr";
import * as fromApp from "../../ngrx/app.reducers";
import { SenecaResponse, UserAcknowledges, JwtPayload } from "../../../cm2-commonclasses";
import { TranslateService } from "@ngx-translate/core";
import { UrlService } from "src/app/shared/services/url.service";
import { DeviceDetectorService } from 'ngx-device-detector';
import { doApplicationNameRedirect } from 'src/app/ngrx/util';
// Importo il componente per decodificare il token JWT
const jwtDecode = require("jwt-decode");

@Injectable()
export class AuthEffects {
  constructor(
    private urlService: UrlService,
    private store: Store<fromApp.AppState>,
    private actions$: Actions,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute
  ) {
    let redirectUrl$: Observable<string> = this.store.select(
      fromApp.getRedirectUrl
    );
    redirectUrl$.subscribe(redirectUrl => {
      this.redirectUrl = redirectUrl;
    });
  }

  token: string;
  tinyToken: string;
  redirectUrl: string;
  isSso: boolean;
  authDataMail: string;
  tinyTokenObj;
  longTokenObj;

  @Effect()
  authLogin$ = this.actions$
    .pipe(
      ofType(AuthActions.DO_LOGIN)
      , map((action: AuthActions.DoLogin) => {
        return action.payload;
      }),
      switchMap(
        (authData: {
          email: string;
          password: string;
          isSso?: boolean;
        }) => {

          this.isSso = authData.isSso;
          this.authDataMail = authData.email;
          const deviceInfo = this.deviceService.getDeviceInfo();
          const userAgent = deviceInfo && deviceInfo.userAgent;
          let deviceType;
          if (this.deviceService.isMobile()) {
            // Salvo il fatto che è uno smartphone
            deviceType = "P";
          } else if (this.deviceService.isTablet()) {
            // Salvo il fatto che è un tablet
            deviceType = "T";
          } else if (this.deviceService.isDesktop()) {
            // Salvo il fatto che è un computer desktop
            deviceType = "D";
          }
          return from(
            this.authService.login(
              authData.email,
              authData.password,
              deviceType,
              userAgent
            )
          );
        }
      ),
      switchMap((tinyTokenObj: SenecaResponse<any>) => {
        if (tinyTokenObj.error) {
          throw new Error(tinyTokenObj.error);
        }
        this.tinyTokenObj = tinyTokenObj;
        return from(this.authService.getJWTToken(tinyTokenObj.response));
      }),
      mergeMap((tokenObj: SenecaResponse<any>) => {
        if (tokenObj.error) {
          throw new Error(tokenObj.error);
        }
        this.longTokenObj = tokenObj.response;
        return from(this.authService.getAvailableApps(this.tinyTokenObj.response));
      }),
      mergeMap((data: SenecaResponse<any>) => {
        let decodedJwt: JwtPayload = jwtDecode(this.longTokenObj);

        let actionsContainer = [];

        if (data.response && Array.isArray(data.response) && data.response.length > 1) {
          actionsContainer.push(
            {
              type: AuthActions.SET_TOKEN,
              payload: this.tinyTokenObj.response
            },
            {
              type: AuthActions.SET_USER_AUTHENTICATED
            },
            {
              type: CoreActions.REMOVE_REDIRECT_URL
            },
            {
              type: CoreActions.START_RENEW_TOKEN_POLLING
            }
          );
        } else if (data.response && Array.isArray(data.response) && data.response.length == 1) {
          sessionStorage.setItem('token', this.tinyTokenObj.response);
        }

        doApplicationNameRedirect(data.response, decodedJwt, this.urlService, this.router, this.redirectUrl);

        return actionsContainer;
      }),
      catchError((err, caught) => {
        if (err && err.message) {
          this.toastr.error(this.translate.instant("errors." + err.message));
        }
        return caught;
      })
    );

  // Effect che recupera la lista delle userAcknowledges dell'utente collegato
  @Effect()
  acknowledgedGet = this.actions$
    .pipe(
      ofType(AuthActions.RETRIEVE_USER_ACKNOWLEDGES)
      , switchMap(() => {
        return from(this.authService.getAllUserAcknowledges());
      })
      , map(
        (data: SenecaResponse<UserAcknowledges>) => {
          if (data) {
            if (data.error) {
              // Catturo l'errore
              throw (new Error(data.error));
            } else {
              return {
                type: AuthActions.SET_USER_ACKNOWLEDGES,
                payload: data.response
              }
            }
          }
        }
      )
      , catchError((err, caught) => {
        if (err && err.message) {
          this.toastr.error(this.translate.instant('errors.' + err.message));
        }
        this.store.dispatch(new AuthActions.SetUserAcknowledges(null));
        return caught;
      })
    );

  // Effect che aggiorna la lista delle userAcknowledges dell'utente collegato
  @Effect()
  acknowledgedUpdate = this.actions$
    .pipe(
      ofType(AuthActions.UPDATE_USER_ACKNOWLEDGES)
      , switchMap((action: any) => {
        return from(this.authService.updateUserAcknowledges(action.payload));
      })
      , map(
        (data: SenecaResponse<UserAcknowledges>) => {
          if (data) {
            if (data.error) {
              // Catturo l'errore
              throw (new Error(data.error));
            } else {
              return {
                type: AuthActions.RETRIEVE_USER_ACKNOWLEDGES,
                payload: null
              }
            }
          }
        }
      )
      , catchError((err, caught) => {
        if (err && err.message) {
          this.toastr.error(this.translate.instant('errors.' + err.message));
        }
        this.store.dispatch(new AuthActions.SetUserAcknowledges(null));
        return caught;
      })
    );

  @Effect()
  authLogout$ = this.actions$
    .pipe(
      ofType(AuthActions.LOGOUT)
      , switchMap(() => {
        return from(this.authService.logout());
      })
      , mergeMap(
        (data: SenecaResponse<any>) => {
          if (data.response === null && data.error === null) {

            let actionsContainer = [
              {
                type: AuthActions.SESSION_LOGOUT
              },
              {
                type: AuthActions.SET_USER_ACKNOWLEDGES,
                payload: null
              },
              {
                type: CoreActions.REMOVE_APPLICATION_LANG
              }
            ];
            this.router.navigate(['/login']);
            return actionsContainer;
          }
        }
      )
      , catchError((err, caught) => {
        if (err && err.message) {
          this.toastr.error(this.translate.instant('errors.' + err.message));
        }
        return caught;
      })
    );
}

