import { Injectable } from '@angular/core';
import { MagicModalComponent } from './magic-modal.component';

/*
* Servizio che gestisce le modali
*/
@Injectable()
export class MagicModalService {
  constructor() {
  }

  // Elenco di modali attive
  private modals: MagicModalComponent[] = [];

  // Aggiunge la modale al contenitore di tutte le modali
  add(modal: MagicModalComponent) {
    this.modals.push(modal);
  }

  // Rimuove una specifica modale dalla lista di quelle attiva
  remove(id: string) {
    this.modals = this.modals.filter(x => x.id !== id);
  }

  // Apre una modale dato il suo ID
  open(id: string) {
    if (!id) {
      return;
    }
    let modal: MagicModalComponent = this.modals.filter(x => x.id === id)[0];
    modal.open();
  }

  // Chiude una modale dato il suo ID
  close(id: string) {
    let modal: MagicModalComponent = this.modals.filter(x => x.id === id)[0];
    modal.close();
  }
}