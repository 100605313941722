import { CommonConstants } from "atfcore-commonclasses";

/*
*    Servizio che costruisce e tornag li URL delle varie applicazioni
*/
export class UrlService {
  private development: boolean;
  private staging: boolean;
  private production: boolean;

  constructor() {
    this.checkHostName();
  }

  checkHostName() {

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    // Per debug verifico se il primo pezzo del dominio Ã¨ "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      this.development = true;
    }
    else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
      this.development = true;
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      this.staging = true;
    }
    else {
      this.production = true;
    }
  }

  // Ritorna l'URL dell'applicazione
  getApplicationUrl() {
    let applicationUrl: any = {
      url: null,
      baseUrl: null,
      protocol: '',
      domain: ''
    }
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    applicationUrl.protocol = window.location.protocol === "http:" ? "http" : "https";

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio Ã¨ "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      // applicationUrl.domain = "atfpm.alloy.it";
      applicationUrl.domain = "atfpm2022.alloy.it";
      this.development = true;
    }
    else if ((window.location.hostname.indexOf("atfpm") == 0 && firstDot > 0)) {
      applicationUrl.domain = "atfpm2022.alloy.it";
      this.development = true;
    }
    else if ((window.location.hostname.indexOf("atfpmdemo") == 0 && firstDot > 0)) {
      applicationUrl.domain = "atfpm2022demo.alloy.it";
      this.development = true;
    } else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      let url = window.location.hostname.substring(0, firstDot);
      if (url) {
        url = url + ".";
      }
      applicationUrl.domain = url + secondLevelDomain;
      this.staging = true;
    }
    // DA TENERE E COMMITTARE SEMPRE
    else {
      //domain = "coursemanager." + secondLevelDomain;
      let url = window.location.hostname.substring(0, firstDot);
      if (url) {
        url = url + ".";
      }
      applicationUrl.domain = url + secondLevelDomain;
      this.production = true;
    }
    applicationUrl.baseUrl = applicationUrl.protocol + "://" + applicationUrl.domain + ":" + port + "/";
    applicationUrl.url = applicationUrl.protocol + "://" + applicationUrl.domain;
    applicationUrl.domain = applicationUrl.domain;
    return applicationUrl;

    // PER TEST
    // else {
    //     applicationUrl.domain = '192.168.1.50';
    //     // opzione default, commentare e cambiare ip sopra per test locale
    //     // applicationUrl.domain = 'atfpm.alloy.it';
    //     this.development = true;
    // }
    // applicationUrl.baseUrl = applicationUrl.protocol + "://" + applicationUrl.domain + "/";
    // applicationUrl.url = applicationUrl.protocol + "://" + applicationUrl.domain;
    // return applicationUrl;
  }

  // Ritorna l'URL dell'applicazione
  getSSOUrl(deviceType: string, userAgent: string, urlExtension?: string) {
    let domain = "";
    let ssoUrl: string = null;
    let ssoReturnPage: string = null;
    // Calcolo il dominio di appartenenza
    const protocol = window.location.protocol === "http:" ? "http" : "https";

    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    const lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    let secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio Ã¨ "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      domain = "localhosts.alloy.it";
    }
    else if ((window.location.hostname.indexOf("atf") == 0 && firstDot > 0)) {
      domain = "atfs.alloy.it";
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      domain = "testsso." + secondLevelDomain;
    }
    else {
      domain = "sso.generalilearningplatform.com";
    }
    ssoReturnPage = this.getApplicationUrl().url;
    if (urlExtension && urlExtension.length) {
      ssoReturnPage = ssoReturnPage + urlExtension;
    };
    ssoUrl = protocol + "://" + domain + "/login?originApplicationName=" + encodeURIComponent(CommonConstants.APPLICATION_PERFORMANCEMANAGEMENT) + "&applicationUrl=" + encodeURIComponent(ssoReturnPage) + "&deviceType=" + encodeURIComponent(deviceType) + "&userAgent=" + encodeURIComponent(userAgent);
    return ssoUrl;
  }

  // Metodo che ritorna l'URL per della GLP
  getGLPURL(addDefaultDestinationPage?: boolean) {
    let protocol = "";
    let secondLevelDomain = "";
    let thirdLevelDomain = "";
    let url: string = null;
    // Calcolo il dominio di appartenenza per chiamare i servizi Rest
    let port = window.location.protocol === "http:" ? 80 : 443;
    protocol = window.location.protocol === "http:" ? "http" : "https";
    // Recupero il nome del dominio per caricare l'iFrame di cross-domain local storage
    let firstDot = 0;
    let lastDot = window.location.hostname.lastIndexOf(".");
    if (lastDot != -1) {
      firstDot = window.location.hostname.lastIndexOf(".", lastDot - 1);
    }
    if (firstDot == -1) {
      firstDot = 0;
    }
    secondLevelDomain = window.location.hostname.substring(firstDot, window.location.hostname.length);
    if (secondLevelDomain.charAt(0) == ".") {
      secondLevelDomain = secondLevelDomain.substring(1, window.location.hostname.length);
    }
    // Per debug verifico se il primo pezzo del dominio è "localhost o atf", allora tengo questo invece del "www"
    if (window.location.hostname.indexOf("localhost") == 0) {
      thirdLevelDomain = "localhost.";
    }
    else if (window.location.hostname.indexOf("atf") == 0 && firstDot > 0) {
      thirdLevelDomain = "atf.";
    }
    else if (window.location.hostname.indexOf("test") == 0 && firstDot > 0) {
      thirdLevelDomain = "test.generalilearningplatform.com";
      secondLevelDomain = '';
    }
    else {
      thirdLevelDomain = "generalilearningplatform.com";
      secondLevelDomain = '';
    }

    url = protocol + "://" + thirdLevelDomain + secondLevelDomain;

    /* Il routing di Angular inserisce in coda all'url il nome della pagina di default, mettendolo dopo anche eventuali parametri in Query string. Pertanto se, ad esempio, sto facendo un semplice redirect
     * alla Library appenendoci il Token dell'utente (es http://localhost.alloy.it?token=DFGH39ODS), Angular lo trasformerà in http://localhostl.alloy.it?token=DFGH39ODS/#/app/login. Per prevenire tale
     * comportamento è necessario quindi specificare una pagina precisa prima degli eventuali parametri in query string. L'URL definitivo sarà, ad esempio, http://localhostl.alloy.it/#/app/login?token=DFGH39ODS
    */
    if (addDefaultDestinationPage) {
      url += "/#/app/login";
    }
    return url;
  };

  // Proxa l'url di un'immagine
  getExternalResourceName = (requestUrl: string, requestName: string) => {
    return this.getApplicationUrl().baseUrl + 'rest-api/coursemanager-mediator/proxy-url/' + encodeURIComponent(requestName) + '?requestUrl=' + encodeURIComponent(requestUrl);
  }
}
