import * as CommonClasses from "../../../cm2-commonclasses";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { UrlService } from "../../shared/services/url.service";
import { CommonConstants, UserOptions, Engagement, RequiredAuth, NotificationQueue, UserAcknowledges } from "../../../cm2-commonclasses";
import { Router } from '@angular/router';

@Injectable()
export class AuthService {
  applicationContext: string;
  constructor(private urlService: UrlService,
    private router: Router,
    private http: HttpClient) {
    this.applicationContext = this.urlService.getApplicationUrl().baseUrl;
  }

  // Servizio che tenta il login
  login(email: string, password: string, device?: string, userAgent?: string): any {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/performancemanagement2022-mediator/login-local-password', { email: email, password: password, langCode: 'it', deviceType: device, userAgent: userAgent });
  }

  // Servizio che aggiorna una ack
  updateUserAcknowledges(userAcknowledges: UserAcknowledges): any {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/performancemanagement2022-mediator/update-userAcknowledges', {
      userAcknowledges: userAcknowledges
    });
  }

  // Servizio che recupera il JWT token dato un tiny token
  getAllUserAcknowledges() {
    return this.http.get<CommonClasses.SenecaResponse<UserAcknowledges>>(this.applicationContext + 'rest-api/performancemanagement2022-mediator/get-all-userAcknowledges');
  }

  updateUserOptions(userOptions: UserOptions) {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/performancemanagement2022-mediator/update-userOptions', {
      userOptions: userOptions
    });
  }

  isAdminMode() {
    // Verifica se sono nella parte amministrativa
    if (this.router.url &&
      (this.router.url.indexOf('admin') !== -1)) {
      return true;
    } else {
      return false;
    }
  }

  isHrbpMode() {
    // Verifica se sono nella parte amministrativa
    if (this.router.url &&
      (this.router.url.indexOf('hrbp') !== -1)) {
      return true;
    } else {
      return false;
    }
  }

  isManagerMode() {
    // Verifica se sono nella parte amministrativa
    if (this.router.url &&
      (this.router.url.indexOf('users') !== -1)) {
      return true;
    } else {
      return false;
    }
  }

  // Metodo per la gestione dei diritti e autenticazione
  isUserAuthorized(requiredAuthId: string, auths: string[]) {
    let isAuthorized: boolean = false;

    for (let i = 0, authsLength = auths.length; i < authsLength; i++) {
      let currentAuth = auths[i];
      if (currentAuth === requiredAuthId) {
        isAuthorized = true;
        break;
      }
    }
    return isAuthorized;
  }

  // Servizio che recupera il JWT token dato un tiny token
  getJWTToken(token: string): any {
    return this.http.get<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/performancemanagement2022-mediator/get-full-jwt-token', { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  // Servizio che recupera un token nuovo
  renewToken(sessionStorageToken: string, forceRefreshUser?: boolean): any {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/performancemanagement2022-mediator/renew-token', { forceRefreshUser: forceRefreshUser }, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + sessionStorageToken) });
  }

  // Servizio che setta una nuova password
  setNewPassword(oldPassword: string, newPassword: string): any {
    return this.http.post<CommonClasses.SenecaResponse<string>>(this.applicationContext + 'rest-api/performancemanagement2022-mediator/set-new-password', { oldPassword: oldPassword, newPassword: newPassword });
  }

  // Logout
  logout() {
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/performancemanagement2022-mediator/logout', {
    });
  }

  // Ritrova il token dall'ssortkqp
  retrieveTokenAfterLogin(retrieveKey: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('retrieveKey', retrieveKey);
    return this.http.get<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/performancemanagement2022-mediator/retrieve-token-after-login', {
      params: httpParams
    });
  }

  // Crea in base al token l'ssorqtp
  crateRetrieveTokenAfterLogin(): any {
    return this.http.post<CommonClasses.SenecaResponse<any>>(this.applicationContext + 'rest-api/performancemanagement2022-mediator/create-retrieve-token-after-login', null);
  }

  // Torna gli applicationName dell'utente
  getAvailableApps(token: string) {
    const applicationContext = this.urlService.getApplicationUrl().baseUrl;
    return this.http.get<any>(applicationContext + 'rest-api/performancemanagement2022-mediator/get-my-available-applications', { headers: new HttpHeaders().set('Authorization', 'Bearer ' + token) });
  }

  // Torna gli applicationName dell'utente senza token perchè ce lha già
  getAvailableAppsWithoutToken() {
    const applicationContext = this.urlService.getApplicationUrl().baseUrl;
    return this.http.get<any>(applicationContext + 'rest-api/performancemanagement2022-mediator/get-my-available-applications');
  }
}