import * as CoreActions from './core.actions';
import { GlobalApplicationData } from 'projects/gi/src/app/shared/models/global-application-data.model';

export interface CoreState {
  globalApplicationData: GlobalApplicationData,
  isFetchingLangs: boolean,
  showApplicationLoader: boolean,
  isMainMenuSidebarOpened: boolean,
  isProfileMenuSidebarOpened: boolean,
  redirectUrl: string,
  applicationLang: string,
  defaultLang: string,
  notificationsCounter: number;
  phasesInfo: any;
  isFetchingPhasesInfo: boolean;
  isFetchingCurrentYear: boolean;
  currentYear: string;
  isImpersonification: boolean;
  isFetchingAskedCountCompetences: boolean;
  askedCountCompetences: number;
}

const initialState: CoreState = {
  globalApplicationData: null,
  isFetchingLangs: false,
  showApplicationLoader: false,
  isMainMenuSidebarOpened: false,
  isProfileMenuSidebarOpened: false,
  redirectUrl: null,
  applicationLang: null,
  defaultLang: null,
  notificationsCounter: 0,
  phasesInfo: null,
  isFetchingPhasesInfo: false,
  isFetchingCurrentYear: false,
  currentYear: null,
  isImpersonification: false,
  isFetchingAskedCountCompetences: false,
  askedCountCompetences: 0
};

export function coreReducer(state = initialState, action: CoreActions.CoreActions) {
  switch (action.type) {
    case (CoreActions.SAVE_REDIRECT_URL):
      return {
        ...state,
        redirectUrl: action.payload
      };
    case (CoreActions.REMOVE_REDIRECT_URL):
      return {
        ...state,
        redirectUrl: null
      };
    case (CoreActions.SET_CORE_APPLICATION_DATA):
      return {
        ...state,
        globalApplicationData: action.payload
      };
    case (CoreActions.SET_APPLICATION_LANG):
      return {
        ...state,
        applicationLang: action.payload
      };
    case (CoreActions.REMOVE_CORE_APPLICATION_DATA):
      return {
        ...state,
        globalApplicationData: null
      };
    case (CoreActions.REMOVE_APPLICATION_LANG):
      return {
        ...state,
        applicationLang: null
      };
    case (CoreActions.GET_AVAILABLE_LANGS):
      return {
        ...state,
        isFetchingLangs: true
      };
    case (CoreActions.GET_AVAILABLE_LANGS_FINISHED):
      return {
        ...state,
        isFetchingLangs: false
      };
    case (CoreActions.SET_DEFAULT_LANG):
      return {
        ...state,
        defaultLang: action.payload
      };
    case (CoreActions.OPEN_MAIN_MENU_SIDEBAR):
      return {
        ...state,
        isMainMenuSidebarOpened: true
      };
    case (CoreActions.CLOSE_MAIN_MENU_SIDEBAR):
      return {
        ...state,
        isMainMenuSidebarOpened: false
      };
    case (CoreActions.OPEN_PROFILE_MENU_SIDEBAR):
      return {
        ...state,
        isProfileMenuSidebarOpened: true
      };
    case (CoreActions.CLOSE_PROFILE_MENU_SIDEBAR):
      return {
        ...state,
        isProfileMenuSidebarOpened: false
      };
    case (CoreActions.SET_NOTIFICATIONS_COUNTER):
      return {
        ...state,
        notificationsCounter: action.payload
      };
    case (CoreActions.SET_PHASES):
      return {
        ...state,
        isFetchingPhasesInfo: false,
        phasesInfo: [...action.payload]
      };
    case (CoreActions.GET_PHASES_INFO):
      return {
        ...state,
        isFetchingPhasesInfo: true
      };
    case (CoreActions.GET_CURRENT_YEAR):
      return {
        ...state,
        isFetchingCurrentYear: true
      };
    case (CoreActions.SET_CURRENT_YEAR):
      return {
        ...state,
        isFetchingCurrentYear: false,
        currentYear: action.payload
      };
    case (CoreActions.SET_IMPERSONIFICATION_BANNER):
      return {
        ...state,
        isImpersonification: action.payload
      };
    case (CoreActions.START_COUNT_ASKED_COMPETENCES):
      return {
        ...state,
        isFetchingAskedCountCompetences: true
      };
    case (CoreActions.SET_ASKED_COMPETENCES):
      return {
        ...state,
        askedCountCompetences: action.payload,
        isFetchingAskedCountCompetences: false
      };
    default:
      return state;
  }
}

export const getGlobalApplicationData = (state: CoreState) => state.globalApplicationData;
export const isFetchingLangs = (state: CoreState) => state.isFetchingLangs;
export const showApplicationLoader = (state: CoreState) => state.showApplicationLoader;
export const isMainMenuSidebarOpened = (state: CoreState) => state.isMainMenuSidebarOpened;
export const isProfileMenuSidebarOpened = (state: CoreState) => state.isProfileMenuSidebarOpened;
export const getRedirectUrl = (state: CoreState) => state.redirectUrl;
export const getApplicationContext = (state: CoreState) => state.globalApplicationData.applicationContext;
export const getApplicationLang = (state: CoreState) => state.applicationLang;
export const getDefaultLang = (state: CoreState) => state.defaultLang;
export const getNotificationsCounter = (state: CoreState) => state.notificationsCounter;
export const getPhasesInfo = (state: CoreState) => state.phasesInfo;
export const isFetchingPhasesInfo = (state: CoreState) => state.isFetchingPhasesInfo;
export const isFetchingCurrentYear = (state: CoreState) => state.isFetchingCurrentYear;
export const getCurrentYear = (state: CoreState) => state.currentYear;
export const getIsImpersonification = (state: CoreState) => state.isImpersonification;
export const getAskedCountCompetences = (state: CoreState) => state.askedCountCompetences;
export const isFetchingAskedCountCompetences = (state: CoreState) => state.isFetchingAskedCountCompetences;