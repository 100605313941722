import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ModalService } from './modal.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() id: string;
  @Input() isFilterComparisonModal: boolean;
  @Input() modalType: string; // 'profile-marks',
  @Input() isModalBadge: boolean;
  @Input() firstTabIndex;
  @Input() showBackButton;
  @Input() closeWithoutButton;
  @Input() noPadding: boolean;
  @Input() historyModal: boolean;
  @Input() baselineModal: boolean;
  @Input() isExperienceVideoModal: boolean;
  @Input() isExperienceNewCompetenceModal: boolean;
  @Input() infoIcon: boolean;
  @Input() isNewCompetenceMyExp: boolean;
  @Input() isBaselineExclude: boolean;
  @Input() isDesign2BeDevAreaModal: boolean;
  @Input() isDesign2BeTutorialVideoModal: boolean;
  @Input() dontCloseModal: boolean;
  @Output() onCloseWithoutButtonFunction = new EventEmitter<any>();
  @Output() onBackButtonFunction = new EventEmitter<any>();
  @Output() onInfoIconOpenModalFunction = new EventEmitter<any>();

  private element: any;
  @ViewChild('modalEl') modalEl;
  focusedElBeforeOpen: any;

  constructor(
    private modalService: ModalService,
    private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    const modal = this;
    // Devo assicurarmi di avere l'id della modale
    if (!this.id) {
      return;
    }

    // Affinché venga resa visibile ovunque, la appendo appena dopo la chiusura del </body>
    document.body.appendChild(this.element);

    // Chiudo la modale al click sullo sfondo
    this.element.addEventListener('click', (e: any) => {
      if (e.target.className === 'modal') {
        if (this.closeWithoutButton) {
          this.onCloseWithoutButtonFunction.emit()
        }
        if (!this.dontCloseModal) {
          modal.close();
        }
      }
      if (e.target.innerHTML == ("Indietro" || "Back")) {
        if (this.isExperienceNewCompetenceModal) {
          this.onBackButtonFunction.emit();
        }
      }
    });

    // Aggiungo la modale al servizio affinché venga resa accessibile dai vari componenti
    this.modalService.add(this);
  }

  // Apre la modale
  open(): void {
    this.focusedElBeforeOpen = document.activeElement;
    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
    setTimeout(() => {
      this.modalEl.nativeElement.focus();
    }, 500)
  }

  // Chiude la modale
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
    if (this.focusedElBeforeOpen) {
      this.focusedElBeforeOpen.focus();
    }
  }

  // Apri modale dall'icona info
  openModalInfoIcon() {
    this.onInfoIconOpenModalFunction.emit();
  }

  // Quando la direttiva è distrutta, tolgo la modale dal servizio
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    if (typeof this.element.remove === 'function') {
      this.element.remove();
    } else {
      this.element.parentNode.removeChild(this.element);
    }
  }
}
