<div class="searchbar-container" [ngClass]="getContainerClass()" fxLayout="row" fxLayoutAlign="start center">
  <form class="input-search-container" fxLayout="row" fxLayoutAlign="start center" (ngSubmit)="onSubmit()">
    <div class="icon-container" fxLayout="row" fxLayoutAlign="center center">
      <svg-icon *ngIf="!fromNinebox" src="assets/img/icons/search-icon.svg"></svg-icon>
      <svg-icon *ngIf="fromNinebox" src="assets/img/icons/search-icon-ninebox.svg"></svg-icon>
    </div>
    <input type="text" name="search" [placeholder]="placeholder" attr.aria-label="{{placeholder | translate}}"
      (ngModelChange)="updateSearchedText($event)" [(ngModel)]="bindValue">
  </form>
  <button class="search-btn" tabindex="0" (click)="onSubmit()" [disabled]="disabled">
    <div fxLayout="row" fxLayoutAlign="center center">
      <p translate="generic.SEARCH"></p>
    </div>
  </button>
</div>