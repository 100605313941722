import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { JwtPayload } from "../cm2-commonclasses";
import * as AuthActions from "./auth/ngrx/auth.actions";
import * as CoreActions from "./core/ngrx/core.actions";
import * as fromApp from "./ngrx/app.reducers";
import * as ProfileActions from "./users/profile/ngrx/profile.actions";
import { RedirectService } from './shared/services/redirect.service';
import { ModalService } from './shared/components/modal/modal.service';
import { UrlService } from './shared/services/url.service';
import { AuthService } from './auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ThemePalette } from '@angular/material/core';
import { authControl } from './shared/models/global-application-data.model';
// Importo il componente per decodificare il token JWT
const jwtDecode = require("jwt-decode");
// (window as any).pdfWorkerSrc = './scripts/pdf.worker.js';
// (window as any).pdfWorkerSrc = import('pdfjs-dist/webpack');

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  showApplicationLoader: boolean;
  isFetchingLangs$: Observable<boolean>;
  isAuthenticated: boolean;
  result$: Subscription;
  routerEvents: Subscription;
  isMainMenuSidebarOpened: boolean;
  isProfileMenuSidebarOpened: boolean;
  avatar: string;
  loggedUser: JwtPayload;
  isFooterOpened: boolean = false;
  now = new Date();
  initialAnimateEnabled: boolean = false;
  libraryUrl: any;
  notificationList;
  isTouchpointOpened: boolean = false;
  currentYear;
  getPhasesData$: any;
  performanceId: any;
  skillsId: any;
  potentialId: any;
  idpId: any;
  feedbackId: any;
  goalId: any;
  isLoadingPhases: boolean;
  result2$: Subscription;
  authObject: any;
  isAdmin: boolean;
  isHRBP: boolean;
  isManager: boolean;
  isImpersonificationBanner: boolean;
  blockedFeedback: boolean;
  window = window;
  /****************** Sidebar User ************************/
  public sidebarUserStatus: any = null;
  public sidebarUserOpened = false;
  public sidebarScheda: any;
  public tabs;
  public currentTabId = 'all';
  public color: ThemePalette = 'accent';
  public checked = false;
  public disabled = false;
  getCurrentYear$: Subscription;
  /****************** Sidebar User ************************/
  redirectUrl: string;

  constructor(
    private modalService: ModalService,
    public redirectService: RedirectService,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private toastr: ToastrService,
    private urlService: UrlService,
    public authService: AuthService,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    let redirectUrl$: Observable<string> = this.store.select(
      fromApp.getRedirectUrl
    );
    redirectUrl$.subscribe(redirectUrl => {
      this.redirectUrl = redirectUrl;
    });
    /*this.route.queryParams.subscribe(params => {
      const ssortkqp = params && params['ssortkqp'] && decodeURIComponent(params['ssortkqp']);
      // Se ho l'ssortkqp significa che sto tornando dal login sso, quindi sono nell'url https://performanceup.generali.it/?ssortkqp=64f64619f308422c9a4a935979b8d265
      // Pertanto devo recuperare il token contempleto dell'utente per fare il redirect
      if (ssortkqp) {
        const getTokenFromKeyPromise = this.getTokenFromSsortkqp(ssortkqp);
        getTokenFromKeyPromise.then((token: string) => {
          if (token && token.length) {
            sessionStorage.setItem("token", token);
            // TODO: "token" contiene solo il tiny token, quindi devo prendere il full token
            const decodedJwt: JwtPayload = jwtDecode(token);
            doTenantRedirect(decodedJwt, this.urlService, this.router, this.redirectUrl);
          } else {
            // Token non valido, vado alla pagina 403
            return this.router.navigateByUrl('/403');
          }
        })
      }
    }); */

    this.getCurrentYear$ = this.store.select(fromApp.getCurrentYear)
      .subscribe((year: any) => {
        this.currentYear = year;

        // Recupero le informazioni sull'utente loggato dallo Store applicativo
        let loggedUser$: Observable<any> = this.store.select(fromApp.getLoggedUser);
        // Mostro il loader mentre sto caricando le lingue
        this.isFetchingLangs$ = this.store.select(fromApp.isFetchingLangs);
        // Mostro il loader se esplicitato nello Store
        let showApplicationLoader$ = this.store.select(
          fromApp.showApplicationLoader
        );
        // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di sinistra contente il menu principale Ã¨ aperta
        let isMainMenuSidebarOpened$: Observable<boolean> = this.store.select(
          fromApp.isMainMenuSidebarOpened
        );
        // Recupero dallo Store applicativo la variabile che mi indica che la sidenav di destra contente il menu utente Ã¨ aperta
        let isProfileMenuSidebarOpened$: Observable<boolean> = this.store.select(
          fromApp.isProfileMenuSidebarOpened
        );
        // Quando il componente Ã¨ stato inizializzato, recupero la parte relativa all'autorizzazione dallo state applicativo e l'oggetto globale "globalApplicationData"
        let isAuthenticated$ = this.store.select(fromApp.isAuthenticated);
        let isImpersonificationBanner$: Observable<boolean> = this.store.select(fromApp.getIsImpersonification);
        // Combito tutti gli observable
        let combinedSelectes$ = combineLatest(loggedUser$, isAuthenticated$, showApplicationLoader$, isMainMenuSidebarOpened$, isProfileMenuSidebarOpened$, isImpersonificationBanner$);
        this.result$ = combinedSelectes$.subscribe(
          ([loggedUser, isAuthenticated, showApplicationLoader, isMainMenuSidebarOpened, isProfileMenuSidebarOpened, isImpersonificationBanner]) => {
            this.loggedUser = loggedUser;
            this.isAuthenticated = isAuthenticated;
            this.showApplicationLoader = showApplicationLoader;
            this.isMainMenuSidebarOpened = isMainMenuSidebarOpened;
            this.isImpersonificationBanner = isImpersonificationBanner;
            if (this.isMainMenuSidebarOpened) {
              this.store.dispatch(new CoreActions.GetPhases({
                year: this.currentYear,
                withCompletionPercentageLoaded: true,
                notReloadData: true
              }));
            }
            this.isProfileMenuSidebarOpened = isProfileMenuSidebarOpened;
          }
        );

        this.result2$ = this.store.select(fromApp.getPhasesInfo).subscribe(
          (phasesInfo) => {
            if (phasesInfo && phasesInfo.length) {
              if (phasesInfo[0].completionPercentage != 100 || phasesInfo[1].completionPercentage != 100 || phasesInfo[2].completionPercentage != 100) {
                this.blockedFeedback = true;
              } else {
                this.blockedFeedback = false;
              }
              this.processPhases(phasesInfo);
            }
          }
        );

        // Recupero l'avatar dell'utente loggato dal suo payload, con una foto di default qualora mancasse
        this.avatar =
          (this.loggedUser &&
            this.loggedUser.user &&
            this.loggedUser.user.userOptions &&
            this.loggedUser.user.userOptions.avatarImage) ||
          (this.loggedUser &&
            this.loggedUser.user &&
            this.loggedUser.user.chiaveSesso == "M"
            ? "assets/img/icons/placeholder.svg"
            : "assets/img/icons/placeholder_female.svg");
      })
  }

  // Recupera token dalla chiave dell'url
  getTokenFromSsortkqp(key: string) {
    return new Promise((resolve, reject) => {
      this.authService.retrieveTokenAfterLogin(key).subscribe((senecaResponse) => {
        if (senecaResponse.error) {
          reject();
        } else {
          if (senecaResponse && senecaResponse.response) {
            resolve(senecaResponse.response);
          } else {
            resolve(null);
          }
        }
      },
        (err) => {
          reject();
        })
    })
  }

  /****************** Sidebar User ************************/
  // Clicchi su una tab nella lista utenti
  /****************** Sidebar User ************************/

  // La sidenav si può chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav nello Store
  closeMainMenuSidenav(): void {
    this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
  }

  // Attiva l'animazione dell'apertura/chiusura delle sidenav
  activateAnimation(): void {
    if (!this.initialAnimateEnabled) {
      this.initialAnimateEnabled = true;
    }
  }

  // Dispatch dell'azione che esegue il toggle sulla sidebar di sinistra col menu principale
  toggleMainMenuSidebar(): void {
    // Alza l'evento per il componente padre, che attive le animazioni sulle sidenav
    this.activateAnimation();
    if (!this.isMainMenuSidebarOpened) {
      this.store.dispatch(new CoreActions.OpenMainMenuSidebar());
    } else {
      this.closeMainMenuSidenav();
    }
  }

  // Verifica in quale pagina sono
  isThisCurrentPage(page: string) {
    if (
      page &&
      this.router &&
      this.router.url &&
      this.router.url.indexOf(page) !== -1
    ) {
      return true;
    }

    return false;
  }

  // Ritorna il nome di una pagina
  getPageName(): string {
    let pageName: string = null;
    /* if (this.isThisCurrentPage("hrbp") || this.isThisCurrentPage("admin")) {
      pageName = this.translate.instant("performance.PERFORMANCE_MANAGEMENT");
    } */
    return '';
  }

  // La sidenav si puÃ² chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav (col menu principale) nello Store
  onMainMenuSidenavClose(): void {
    this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
  }

  // La sidenav si puÃ² chiudere anche cliccando sul backdrop. In tal caso, eseguo il dispatch dell'azione che setta come chiusa la sidenav (col menu principale) nello Store
  onProfileMenuSidenavClose(): void {
    this.store.dispatch(new CoreActions.CloseProfileMenuSidebar());
  }

  touchpointClicked() {
    this.isTouchpointOpened = !this.isTouchpointOpened;
  }

  // Quando il componente Ã¨ distrutto, eseguo anche l'unsubscribe del cambio di route
  ngOnDestroy() {
    this.routerEvents.unsubscribe();
    this.result$.unsubscribe();
  }

  // Logout dall'applicazione
  onLogout() {
    // Al click sul logout, eseguo il dispatch dell'action che disautentica l'utente, cancella il token (sia dal session storage che dallo State), e cancella il Payload (dato dalla decodifica del Token) dentro lo State
    this.store.dispatch(new AuthActions.Logout());
    // Redirect alla pagina di login
    this.router.navigate(["/login"]);
  }

  // Quando il componente si Ã¨ inizializzato ed Ã¨ pronto, devo settare i parametri applicativi come il base URL dell'applicazione. Di questo se ne occuperÃ  il side effect una volta lanciata l'action per recuperare le lingue disponibili a sistema.
  emeraldPathEnabled: any;

  ngOnInit() {
    // Recupero le lingue disponibili
    this.store.dispatch(new CoreActions.GetAvailableLangs());
    if (this.loggedUser) {
      /* this.getPhasesData(); */
    }
  }

  processPhases(data) {
    for (let i = 0; i < data.length; i++) {
      let phase = data[i]
      switch (phase.phaseKey.toLowerCase()) {
        case 'performance':
          this.performanceId = phase.phaseId;
          break;
        case 'competences':
          this.skillsId = phase.phaseId;
          break;
        case 'potencial':
          this.potentialId = phase.phaseId;
          break;
        case 'individual_development_plan':
          this.idpId = phase.phaseId;
          break;
        case 'feedback_dialogue':
          this.feedbackId = phase.phaseId;
          break;
        case 'goal_setting':
          this.goalId = phase.phaseId;
          break;
        default:
          this.toastr.error(this.translate.instant('errors.ITEM_NOT_FOUND'));
          break;
      }
    }
  }

  // Chiude la sidebar di sinistra contente il menu principale
  closeSidenavMainMenu() {
    this.store.dispatch(new CoreActions.CloseMainMenuSidebar());
  }

  // Chiude la sidebar di destra con il profilo utente
  closeSidenavProfileMenu() {
    this.store.dispatch(new CoreActions.CloseProfileMenuSidebar());
  }

  isUsersHome() {
    return window.location.href.indexOf('users/home') >= 0;
  }

  openSupportModal() {
    if (this.isMainMenuSidebarOpened) {
      this.closeSidenavMainMenu();
    }
    if (this.isProfileMenuSidebarOpened) {
      this.closeSidenavProfileMenu();
    }
    this.modalService.open('supportModal');
  }

  ngAfterViewChecked() {
    this.authObject = authControl(this.loggedUser && this.loggedUser.auths);
    this.isAdmin = this.authObject.isAdmin;
    this.isHRBP = this.authObject.isHRBP;
    this.isManager = this.authObject.isManager;
  }
}