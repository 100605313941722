import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss']
})
export class CustomButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() dimension: string;
  @Input() importance: string;
  @Input() leftIcon: string;
  @Input() rightIcon: string;
  @Input() text: string;
  @Input() uppercase: boolean;
  @Input() isLibraryOnlineImg: boolean;
  @Input() fromNinebox: boolean;
  @Input() isAlleanza: boolean;
  @Input() isOrangeColor: boolean;
  @Input() fromMyExp: boolean;
  /** Indica il path da usare per la leftIcon */
  @Input() iconPath: string;
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  getContainerClass() {
    let classList = '';

    if (this.isAlleanza) {
      classList = classList + 'alleanza in-bluebook ';
    }

    if (this.disabled) {
      if (this.fromMyExp) {
        classList = classList + 'disabled my-exp ';
      } else {
        classList = classList + 'disabled ';
      }
    }
    if (!this.dimension) {
      classList = classList + 'btn-regular ';
    } else {
      classList = classList + this.dimension + ' ';
    }
    if (!this.importance) {
      classList = classList + 'primary ';
    } else {
      classList = classList + this.importance + ' ';
    }
    if (this.fromNinebox) {
      classList = classList + 'from-ninebox ';
    }

    if (this.leftIcon) {
      classList = classList + 'has-left-icon ';
    }

    return classList;
  }

  onButtonClick(data?) {
    this.onButtonClicked.emit(data);
  }

}
