<!-- Il form di login si vede solamente se ci sono lingue disponibili a sistema -->
<div class="login-page theme-primary" fxLayout="row" *ngIf="langs && langs.length" fxFlexFill>
  <div class="login-column" fxFlex.sm="50" fxFlex.gt-sm="40" fxFlexFill-xs fxLayout="column"
    fxLayoutAlign="center center">
    <img width="140" src="/assets/img/icons/logo_generali.svg"
      alt="{{'accessibilityAttributes.GENERALI_LOGO' | translate}}"
      title="{{'accessibilityAttributes.GENERALI_LOGO' | translate}}">
    <login-form (onLogin)="onLogin($event)"></login-form>
  </div>
</div>

<!-- Nessuna lingua disponibile a sistema -->
<div *ngIf="!langs || !langs.length">
  <p translate="errors.NO_AVAILABLE_LANG"></p>
</div>